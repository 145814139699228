import React, { useState, useContext } from "react";
import { WebBuilderContext } from "../../../../contexts/context/webBuilderContext";
import TextEditor2 from "../texteditor/TextEditor";
import HorizontalButtonList from "../buttons/ButtonsList";

const Columns = ({ content, blockId }) => {

  const { blockData } = useContext(WebBuilderContext);
  const { webBuilderDispatch } = useContext(WebBuilderContext);

  const [columns, setColumns] = useState([]);

  // React.useEffect(() => {
  //   const block = blockData.blocks.find(block => block.id === blockId);
  //   if (block) {
  //     const columns = block.content.find(theContent => theContent.id === content.id).columns;
  //     setColumns(columns);
  //   }
  // }, [blockData.blocks]);

  return (
    <div className="container position-relative">
      {/* Hover Border for Container */}
      <div
        className="container-hover-border d-none position-absolute w-100 h-100 border border-2"
        style={{ top: 0, left: 0, pointerEvents: "none", zIndex: 5 }}
      ></div>

      {/* Row and Columns */}
      <div className="row" style={{ marginRight: 0, marginLeft: 0 }}>
        {content.columns && content.columns.map((column, index) => {
          return (
            <div
              className="col-md-3 position-relative"
              key={index}
              style={{ paddingRight: 0, paddingLeft: 0 }}
            >
              {/* Hover Border for Card */}
              <div
                className="card-hover-border d-none position-absolute w-100 h-100 border border-1"
                style={{ top: 0, left: 0, pointerEvents: "none", zIndex: 10 }}
              ></div>

              {/* Card Content */}
              <div className="card pb-2 d-flex" style={{ backgroundColor: "transparent", border: "none" }}>
                <div className="card-body p-0 d-flex flex-column" style={{ backgroundColor: "transparent" }}>
                  {
                    column.content.map((itm, itmIndex) => {
                      return (
                        <div key={itm.id} className="rounded-lg bg-transparent text-white hover:bg-gray-100 transition-colors duration-200">
                          {itm.type === "TEXT" ? (
                            <TextEditor2 blockId={blockId} content={itm} contentId={content.id} columnId={column.id} />
                          ) : itm.type === "BUTTONS" ? (
                            <HorizontalButtonList blockId={blockId} content={itm} key={itm.id} />
                          ) : null}
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Columns;
