import React, { useState, useContext, useEffect } from "react";
import { createPortal } from "react-dom";
import { WebBuilderContext } from '../../../../../contexts/context/webBuilderContext';
import { FiAlignJustify, FiEdit, FiMoreHorizontal, FiTrash2 } from "react-icons/fi";
import useWebBuilderActions from "../../../../../contexts/actions/useWebBuilderActions";

const API_URL = process.env.REACT_APP_API_URL;

const SingleImageEditor = ({ blockId, content }) => {
    const { webBuilderDispatch } = useContext(WebBuilderContext);
    const { deleteBlockContent, updateSingleImage } = useWebBuilderActions();
    const [imageData, setImageData] = useState({
        src: "",
        alt: "",
        previewSrc: ""
    });
    const [isEditing, setIsEditing] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        if (content.image) {
            const { src, alt } = content.image;
            setImageData({
                src,
                previewSrc: src,
                alt: alt || "",
            });
        }
    }, [content.image]);

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setIsUploading(true);
        setUploadProgress(0);

        const formData = new FormData();
        formData.append("image", file);

        try {
            // Start progress simulation
            const interval = setInterval(() => {
                setUploadProgress((prev) => {
                    if (prev >= 90) {
                        clearInterval(interval);
                        return 90;
                    }
                    return prev + 10;
                });
            }, 300);

            const response = await fetch(`${API_URL}/web-builder/upload-image`, {
                method: "POST",
                body: formData,
            });

            clearInterval(interval);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (data.status) {
                setImageData(prev => ({
                    ...prev,
                    previewSrc: data.data.filename
                }));
                setUploadProgress(100);
                setTimeout(() => {
                    setIsUploading(false);
                    setUploadProgress(0);
                }, 500);
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            console.error("Upload failed:", error);
            setIsUploading(false);
            setUploadProgress(0);
            alert("Failed to upload image. Please try again.");
            e.target.value = "";
        }
    };

    const handleSave = () => {
        if (!imageData.previewSrc) {
            alert("Please upload an image");
            return;
        }

        const newImage = {
            src: imageData.previewSrc,
            alt: imageData.alt || "Image"
        };

        updateSingleImage(blockId, content.id, newImage);
        setIsEditing(false);
    };

    const MenuButtons = () => (
        <>
            <button
                className="d-flex align-items-center btn btn-link bg-white text-dark"
                onClick={() => { setIsEditing(true); }}
            >
                <FiEdit className="me-2" />
            </button>
            <button
                className="d-flex align-items-center btn btn-link bg-white text-dark"
            >
                <FiAlignJustify className="me-2" />
            </button>
            <button
                className="d-flex align-items-center btn btn-link bg-white text-dark"
            >
                <FiAlignJustify className="me-2" />
            </button>
            <button
                className="d-flex align-items-center btn btn-link bg-white text-dark"
            >
                <FiAlignJustify className="me-2" />
            </button>
            <button
                className="d-flex align-items-center btn btn-link bg-white text-danger"
                onClick={() => { deleteBlockContent(blockId, content.id); }}
            >
                <FiTrash2 className="" />
            </button>
        </>
    );

    return (
        <div
            className="container position-relative py-4"
            onMouseEnter={(e) => {
                setIsHovered(true);
                e.currentTarget.querySelector(".container-hover-border").classList.remove("d-none");
                e.currentTarget.querySelector(".container-three-dot-menu").classList.remove("d-none");
            }}
            onMouseLeave={(e) => {
                setIsHovered(false);
                e.currentTarget.querySelector(".container-hover-border").classList.add("d-none");
                e.currentTarget.querySelector(".container-three-dot-menu").classList.add("d-none");
            }}
            onClick={() => {
                webBuilderDispatch({
                    type: 'UPDATE_CONTENT_ID',
                    payload: { contentId: content.id }
                });
            }}
        >
            <div
                className="container-hover-border d-none position-absolute w-100 h-100 border border-2 border-white"
                style={{ top: 0, left: 0, pointerEvents: "none", zIndex: 5 }}
            ></div>

            <div
                className="container-three-dot-menu d-none position-absolute p-2 bg-light shadow-sm rounded"
                style={{ zIndex: 10, top: "0px", left: "-36px" }}
            >
                <button
                    className="btn btn-link text-black"
                    onClick={() => { setIsMenuOpen((prev) => !prev); }}
                >
                    <FiMoreHorizontal size={20} />
                </button>
                {isMenuOpen && (
                    <div
                        className="d-flex align-items-center position-absolute bg-white shadow-sm rounded py-2"
                        style={{
                            zIndex: 15,
                            top: "8px",
                            left: "50%",
                            right: "50%",
                            width: "fit-content",
                            padding: "4px 10px",
                            transform: "translate(-50%, -120%)",
                        }}
                    >
                        <MenuButtons />
                    </div>
                )}
            </div>

            <div className="text-center mb-4">
                {imageData.src && (
                    <div
                        className="d-inline-block position-relative"
                        style={{
                            maxWidth: "560px",
                            maxHeight: "315px",
                        }}
                    >
                        <img
                            src={imageData.src.startsWith('http') ? imageData.src : `${API_URL}/web-builder/${imageData.src}`}
                            alt={imageData.alt}
                            style={{
                                maxWidth: "100%",
                                height: "auto",
                                borderRadius: "8px",
                            }}
                        />
                        {isHovered && (
                            <div
                                className="position-absolute"
                                style={{
                                    top: 8,
                                    right: 8,
                                    zIndex: 1
                                }}
                            >
                                <button
                                    className="btn btn-light btn-sm"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsEditing(true);
                                    }}
                                >
                                    Edit
                                </button>
                            </div>
                        )}
                    </div>
                )}

                {isHovered && !imageData.src && (
                    <button
                        className="btn btn-primary"
                        onClick={() => setIsEditing(true)}
                    >
                        Add Image
                    </button>
                )}
            </div>

            {isEditing && createPortal(
                <div
                    className="modal show fade d-block"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {imageData.src ? "Edit Image" : "Add Image"}
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => setIsEditing(false)}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label className="form-label">Upload Image</label>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileUpload}
                                        className="form-control"
                                        disabled={isUploading}
                                    />
                                    {isUploading && (
                                        <div className="mt-2">
                                            <div className="progress">
                                                <div 
                                                    className="progress-bar" 
                                                    role="progressbar" 
                                                    style={{ width: `${uploadProgress}%` }}
                                                    aria-valuenow={uploadProgress} 
                                                    aria-valuemin="0" 
                                                    aria-valuemax="100"
                                                >
                                                    {uploadProgress}%
                                                </div>
                                            </div>
                                            <small className="text-muted mt-1 d-block">
                                                Uploading image...
                                            </small>
                                        </div>
                                    )}
                                </div>

                                {imageData.previewSrc && (
                                    <div className="mb-4 text-center">
                                        <img
                                            src={imageData.previewSrc.startsWith('http') ? imageData.previewSrc : `${API_URL}/web-builder/${imageData.previewSrc}`}
                                            alt={imageData.alt}
                                            style={{
                                                maxWidth: "100%",
                                                height: "200px",
                                                objectFit: "contain",
                                                borderRadius: "8px"
                                            }}
                                        />
                                    </div>
                                )}

                                <div className="mb-3">
                                    <label className="form-label">Alt Text</label>
                                    <input
                                        type="text"
                                        value={imageData.alt}
                                        onChange={(e) => setImageData(prev => ({ ...prev, alt: e.target.value }))}
                                        className="form-control"
                                        placeholder="Image description for accessibility"
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setIsEditing(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSave}
                                    disabled={isUploading}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>,
                document.body
            )}
        </div>
    );
};

export default SingleImageEditor;