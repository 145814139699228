import React from 'react';
import TextEditor from './components/texteditor/TextEditor';
import HorizontalButtonList from './components/buttons/ButtonsList';
// import ImageGrid from './components/images/images-grid/ImageGrid';
// import TestimonialList from './components/testimonials/Testimonials';
import SingleImage from './components/images/single-image/SingleImage';
import YoutubeVideo from './components/videos/YoutubeVideo';
import Columns from './components/columns/Columns';
import TextCards from "./components/text-cards/TextCards";
import { WebBuilderContext } from '../../contexts/context/webBuilderContext';

const ComponentsList = ({ blockId, content, blockIndex }) => {
    const { webBuilderDispatch } = React.useContext(WebBuilderContext);

    return (
        <>
            {content.map((item, index) => (
                <div key={item.id} className="container">
                    <div className="d-flex align-items-center container">
                        <div className="flex-grow-1">
                            {item.type === "TEXT" ? (
                                <TextEditor blockId={blockId} content={item} />
                            )
                                : item.type === "BUTTONS" ? (
                                    <HorizontalButtonList blockId={blockId} content={item} />
                                )
                                    // : item.type === "IMAGE_GRID" ? (
                                    //     <ImageGrid blockId={blockId} content={item} />
                                    // ) : item.type === "TESTIMONIALS" ? (
                                    //     <TestimonialList blockId={blockId} content={item} />
                                    // )
                                    : item.type === "SINGLE_IMAGE" ? (
                                        <SingleImage blockId={blockId} content={item} />
                                    )
                                        : item.type === "YOUTUBE_VIDEO" ? (
                                            <YoutubeVideo blockId={blockId} content={item} />
                                        )
                                            : item.type === "COLUMNS" ? (
                                                <Columns blockId={blockId} content={item} contentIndex={index} />
                                            )
                                                : item.type === "CARDS" ? (
                                                    <TextCards blockId={blockId} content={item} />
                                                )
                                                    : null}
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default ComponentsList;
