import React, { useEffect, useState, useContext } from "react";
import { FiAlignJustify, FiEdit, FiMoreHorizontal, FiPlusCircle, FiTrash2 } from "react-icons/fi";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { WebBuilderContext } from "../../../../contexts/context/webBuilderContext";
import TextEditor3 from "../texteditor/TextEditor3";
import HorizontalButtonList from "../buttons/ButtonsList";
import useWebBuilderActions from "../../../../contexts/actions/useWebBuilderActions";

const EnergyCards = ({ content, blockId }) => {
  const { blockData, webBuilderDispatch } = useContext(WebBuilderContext);
  const { addCardToCardGroup, deleteBlockContent, deleteCardFromCardGroup } = useWebBuilderActions();
  const [cards, setCards] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpen1, setIsMenuOpen1] = useState(false);


  const addCard = () => {
    setCards([...cards, {
      id: Date.now().toString(),
      title: "New Energy Source",
      content: []
    }]);
  };

  const removeCard = (index) => {
    setCards(cards.filter((_, i) => i !== index));
  };

  const removeBlock = () => {
    webBuilderDispatch({ type: "REMOVE_BLOCK", payload: { id: blockId } });
  };

  // useEffect(() => {
  //   const block = blockData.blocks.find((block) => block.id === blockId);
  //   if (block) {
  //     const loadedCards = block.content.find(
  //       (theContent) => theContent.id === content.id
  //     ).cards;
  //     setCards(loadedCards);
  //   }
  // }, [blockData.blocks, blockId, content.id]);

  return (
    <div
      className="container position-relative my-4 px-0"
      onMouseEnter={(e) => {
        e.currentTarget.querySelector(".container-hover-border").classList.remove("d-none");
        e.currentTarget.querySelector(".container-three-dot-menu").classList.remove("d-none");
        e.currentTarget.querySelector(".card-plus-menu-icon").classList.remove("d-none");
      }}
      onMouseLeave={(e) => {
        e.currentTarget.querySelector(".container-hover-border").classList.add("d-none");
        e.currentTarget.querySelector(".container-three-dot-menu").classList.add("d-none");
        e.currentTarget.querySelector(".card-plus-menu-icon").classList.add("d-none");
      }}
      style={{ background: "transparent" }}
    >
      {/* Hover Border for Container */}
      <div
        className="container-hover-border d-none position-absolute w-100 h-100 border border-2 border-white"
        style={{ top: 0, left: 0, pointerEvents: "none", zIndex: 5 }}
      ></div>

      {/* 3-Dot Menu for Container */}
      <div
        className="container-three-dot-menu d-none position-absolute p-2 bg-light shadow-sm rounded"
        style={{ zIndex: 10, top: "0px", left: "-36px" }}
      >
        <button
          className="btn btn-link text-black"
          onClick={() => console.log(`3-dot menu clicked for the container`)}
        >
          <FiMoreHorizontal size={20} onClick={() => { setIsMenuOpen1((prev) => !prev); }} />
        </button>

        {isMenuOpen1 && (
          <div
            className="d-flex align-items-center position-absolute bg-white shadow-sm rounded py-2"
            style={{
              zIndex: 15,
              top: "8px",
              left: "50%",
              right: "50%",
              width: "fit-content",
              padding: "4px 10px",
              transform: "translate(-50%, -120%)", // Centers the menu and positions it above
            }}
          >
            <button
              className="d-flex align-items-center btn btn-link bg-white text-dark"
            >
              <FiEdit className="me-2" />
            </button>
            <button
              className="d-flex align-items-center btn btn-link bg-white text-dark"
            >
              <FiAlignJustify className="me-2" />
            </button>
            <button
              className="d-flex align-items-center btn btn-link bg-white text-dark"
            >
              <FiAlignJustify className="me-2" />
            </button>
            <button
              className="d-flex align-items-center btn btn-link bg-white text-dark"
            >
              <FiAlignJustify className="me-2" />
            </button>
            <button
              className="d-flex align-items-center btn btn-link bg-white text-danger"
              onClick={() => deleteBlockContent(blockId, content.id)}
            >
              <FiTrash2 className="" />
            </button>
          </div>
        )}
      </div>

      {/* Plus Icon for Container */}
      <div
        className="card-plus-menu-icon d-none position-absolute bg-light shadow-sm rounded"
        style={{
          zIndex: 15,
          right: "0px",
          top: "0px",
          bottom: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button className="btn btn-link text-black" onClick={() => {

          // addCard();
          addCardToCardGroup(blockId, content.id);

        }}>
          <FiPlusCircle size={20} />
        </button>
      </div>

      <div className="row g-4">
        {content.cards.map((card, index) => {
          const dropable_id = JSON.stringify({
            type: "cards",
            contentId: content.id,
            cardId: card.id,
            blockId: blockId
          });

          return (
            <div
              className="col-12 col-lg-6 col-md-12 col-sm-12 position-relative"
              key={card.id}
              onMouseEnter={(e) => {
                e.currentTarget.querySelector(".card-hover-border").classList.remove("d-none");
                e.currentTarget.querySelector(".card-three-dot-menu").classList.remove("d-none");
              }}
              onMouseLeave={(e) => {
                e.currentTarget.querySelector(".card-hover-border").classList.add("d-none");
                e.currentTarget.querySelector(".card-three-dot-menu").classList.add("d-none");
              }}
            >
              {/* Hover Border for Card */}
              <div
                className="card-hover-border d-none position-absolute w-100 h-100 border border-1 border-white"
                style={{ top: 0, left: 0, pointerEvents: "none", zIndex: 10 }}
              ></div>

              {/* 3-Dot Menu for Card */}
              <div
                className="card-three-dot-menu d-none position-absolute bg-light shadow-sm rounded"
                style={{
                  zIndex: 15,
                  left: "50%",
                  transform: "translateX(-50%)",
                  top: "-20px",
                  padding: "1px 2px 0px 5px",
                }}
              >
                <button
                  className="btn btn-link text-black"
                  onClick={() => { setIsMenuOpen((prev) => !prev); }}
                >
                  <FiMoreHorizontal size={20} />
                </button>

                {isMenuOpen && (
                  <div
                    className="d-flex align-items-center position-absolute bg-white shadow-sm rounded py-2"
                    style={{
                      zIndex: 15,
                      top: "8px",
                      left: "50%",
                      right: "50%",
                      width: "fit-content",
                      padding: "4px 10px",
                      transform: "translate(-50%, -120%)", // Centers the menu and positions it above
                    }}
                  >
                    <button
                      className="d-flex align-items-center btn btn-link bg-white text-dark"
                    >
                      <FiEdit className="me-2" />
                    </button>
                    <button
                      className="d-flex align-items-center btn btn-link bg-white text-dark"
                    >
                      <FiAlignJustify className="me-2" />
                    </button>
                    <button
                      className="d-flex align-items-center btn btn-link bg-white text-dark"
                    >
                      <FiAlignJustify className="me-2" />
                    </button>
                    <button
                      className="d-flex align-items-center btn btn-link bg-white text-dark"
                    >
                      <FiAlignJustify className="me-2" />
                    </button>
                    <button
                      className="d-flex align-items-center btn btn-link bg-white text-danger"
                      onClick={() => { deleteCardFromCardGroup(blockId, content.id, card.id) }}
                    >
                      <FiTrash2 className="" />
                    </button>
                  </div>
                )}
              </div>

              {/* Card Content */}
              <div className="card h-100 bg-transparent text-light text-center d-flex flex-column align-items-center" style={{ borderRadius: "1rem" }}>
                <div className="card-body bg-transparent d-flex flex-column">
                  <Droppable droppableId={dropable_id}>
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="list-group"
                      >
                        {card.content.map((item, itemIndex) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={itemIndex}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`rounded ${snapshot.isDragging
                                  ? "bg-secondary shadow"
                                  : "bg-transparent"
                                  }`}
                              >
                                {item.type === "TEXT" ? (
                                  <TextEditor3 blockId={blockId} content={item} contentId={content.id} cardId={card.id} />
                                ) : item.type === "BUTTONS" ? (
                                  <HorizontalButtonList
                                    blockId={blockId}
                                    content={item}
                                  />
                                ) : null}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>

                  {/* Add Content Button */}
                  {/* <div className="d-flex align-items-center justify-content-center my-3">
                    <div
                      className="d-flex align-items-center justify-content-center bg-warning rounded-circle"
                      style={{
                        height: "30px",
                        width: "30px",
                        cursor: "pointer",
                      }}
                    >
                      <FiPlusCircle className="text-dark" size={20} />
                    </div>
                  </div> */}

                  {/* Remove Button */}
                  {/* <div className="mt-auto">
                    <button
                      className="btn btn-danger w-100"
                      onClick={() => removeCard(index)}
                    >
                      <FiTrash2 className="me-2" />
                      Remove
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EnergyCards;