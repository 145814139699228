import React, { useState, useContext } from "react";
import { FiAlignJustify, FiEdit, FiMoreHorizontal, FiMoreVertical, FiPlusCircle, FiTrash2 } from "react-icons/fi";
import { WebBuilderContext } from "../../../../contexts/context/webBuilderContext";
import TextEditor2 from "../texteditor/TextEditor2";
import HorizontalButtonList from "../buttons/ButtonsList";
import { MdOutlineDragIndicator } from "react-icons/md";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import useWebBuilderActions from "../../../../contexts/actions/useWebBuilderActions";





const Columns = ({ content, blockId }) => {

  const { blockData } = useContext(WebBuilderContext);
  const { deleteBlockContent, addColumnToClumnGroup, deleteColumnFromClumnGroup } = useWebBuilderActions();

  const [columns, setColumns] = useState([]);
  const { webBuilderDispatch } = useContext(WebBuilderContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpen1, setIsMenuOpen1] = useState(false);



  const addColumn = () => {
    setColumns([...columns, { title: "New Column", content: [] }]);
  };

  const removeColumn = (index) => {
    setColumns(columns.filter((_, i) => i !== index));
  };

  const removeBlock = (id) => {
    webBuilderDispatch({ type: 'REMOVE_BLOCK', payload: { id } });
  };

  // React.useEffect(() => {

  //   const block = blockData.blocks.find(block => block.id === blockId);

  //   if (block) {
  //     const columns = block.content.find(theContent => theContent.id === content.id).columns;

  //     setColumns(columns);
  //   }

  // }, [blockData.blocks])




  return (
    <div
      className="container position-relative"
      onMouseEnter={(e) => {
        e.currentTarget
          .querySelector(".container-hover-border")
          .classList.remove("d-none");
        e.currentTarget
          .querySelector(".container-three-dot-menu")
          .classList.remove("d-none");
        // e.currentTarget
        //   .querySelector(".container-six-dot-menu")
        //   .classList.remove("d-none");
        e.currentTarget
          .querySelector(".card-plus-menu-icon")
          .classList.remove("d-none");
      }}
      onMouseLeave={(e) => {
        e.currentTarget
          .querySelector(".container-hover-border")
          .classList.add("d-none");
        e.currentTarget
          .querySelector(".container-three-dot-menu")
          .classList.add("d-none");
        // e.currentTarget
        //   .querySelector(".container-six-dot-menu")
        //   .classList.add("d-none");
        e.currentTarget
          .querySelector(".card-plus-menu-icon")
          .classList.add("d-none");
      }}
    >
      {/* Hover Border for Container */}
      <div
        className="container-hover-border d-none position-absolute w-100 h-100 border border-2"
        style={{ top: 0, left: 0, pointerEvents: "none", zIndex: 5 }}
      ></div>

      {/* 3-Dot Menu for Container */}
      <div
        className="container-three-dot-menu d-none position-absolute p-2 bg-white shadow-sm rounded"
        style={{ zIndex: 10, top: "0px", left: "-36px" }}
      >
        <button
          className="btn btn-link text-secondary"
          onClick={() => setIsMenuOpen1((prev) => !prev)}
        >
          <FiMoreVertical size={20} />
        </button>

        {isMenuOpen1 && (
          <div
            className="d-flex align-items-center position-absolute bg-white shadow-sm rounded py-2"
            style={{
              zIndex: 15,
              left: "50%",
              right: "50%",
              width: 'fit-content',
              padding: "4px 10px",
              // transform: "translate(-50%, -120%)", 
              inset: 'auto auto 0px 0px',
              margin: '0px',
              transform: 'translate(22rem, -2.9rem)'
            }}
          >
            <button
              className="d-flex align-items-center btn btn-link bg-white text-dark"
            >
              <FiEdit className="me-2" />
            </button>
            <button
              className="d-flex align-items-center btn btn-link bg-white text-dark"
            >
              <FiAlignJustify className="me-2" />
            </button>
            <button
              className="d-flex align-items-center btn btn-link bg-white text-dark"
            >
              <FiAlignJustify className="me-2" />
            </button>
            <button
              className="d-flex align-items-center btn btn-link bg-white text-dark"
            >
              <FiAlignJustify className="me-2" />
            </button>
            <button
              className="d-flex align-items-center btn btn-link bg-white text-danger"
              onClick={() => deleteBlockContent(blockId, content.id)}
            >
              <FiTrash2 className="" />
            </button>
          </div>
        )}

        {/* <button
          className="btn btn-link text-danger d-flex align-items-center w-100 px-3"
          // onClick={() => removeBlock()}
        >
          <FiTrash2 size={16} className="me-2" /> Delete
        </button> */}
      </div>

      {/* Drag-and-drop-btn */}
      {/* <div
          className="container-six-dot-menu d-none position-absolute p-2 bg-white shadow-sm rounded"
          style={{ zIndex: 10, top: '0px', right: '-26px' }}
        >
          <button
            className="btn btn-link text-secondary"
            onClick={(e) => {
              e.stopPropagation();
              console.log('6-dot menu clicked');
            }}
          >
            <MdOutlineDragIndicator size={20} />
          </button>
        </div> */}

      <div
        className="card-plus-menu-icon d-none position-absolute bg-white shadow-sm rounded"
        style={{
          zIndex: 15,
          right: "0px",
          top: "0px",
          bottom: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          className="btn btn-link text-secondary"
          onClick={() => {
            if (content.columns.length >= 4) return
            addColumnToClumnGroup(blockId, content.id)
          }}
        >          <FiPlusCircle size={20} />
        </button>
      </div>

      {/* Row and Columns */}
      <div className="row" style={{ marginRight: 0, marginLeft: 0 }}>
        {content.columns && content.columns.map((column, index) => {

          console.log("maped again")

          const dropable_id = JSON.stringify({
            type: "columns",
            contentId: content.id,
            columnId: column.id,
            blockId: blockId
          })

          return (
            <div
              className=" col-12 col-lg-3 col-sm-6 col-md-6  position-relative"
              key={index}
              style={{ paddingRight: 0, paddingLeft: 0 }}
              onMouseEnter={(e) => {
                e.currentTarget
                  .querySelector(".card-hover-border")
                  .classList.remove("d-none");
                e.currentTarget
                  .querySelector(".card-three-dot-menu")
                  .classList.remove("d-none");
              }}
              onMouseLeave={(e) => {
                e.currentTarget
                  .querySelector(".card-hover-border")
                  .classList.add("d-none");
                e.currentTarget
                  .querySelector(".card-three-dot-menu")
                  .classList.add("d-none");
              }}
            >
              {/* Hover Border for Card */}
              <div
                className="card-hover-border d-none position-absolute w-100 h-100 border border-1"
                style={{ top: 0, left: 0, pointerEvents: "none", zIndex: 10 }}
              ></div>

              {/* 3-Dot Menu for Card */}
              <div
                className="card-three-dot-menu d-none position-absolute  shadow-sm rounded"
                style={{
                  zIndex: 15,
                  left: "50%",
                  right: "50%",
                  top: "-10px",
                }}
              >
                <button
                  className="btn btn-link bg-white text-secondary"
                  style={{ padding: "0px 0px 0px 1px" }}
                  onClick={() => setIsMenuOpen((prev) => !prev)}
                >
                  <FiMoreHorizontal size={20} />
                </button>
              </div>

              {isMenuOpen && (
                <div
                  className="d-flex align-items-center position-absolute bg-white shadow-sm rounded py-2"
                  style={{
                    zIndex: 15,
                    left: "50%",
                    right: "50%",
                    width: "fit-content",
                    padding: "4px 10px",
                    transform: "translate(-50%, -120%)", // Centers the menu and positions it above
                  }}
                >
                  <button
                    className="d-flex align-items-center btn btn-link bg-white text-dark"
                    onClick={() => console.log(`Edit layout for card ${index}`)}
                  >
                    <FiEdit className="me-2" />
                  </button>
                  <button
                    className="d-flex align-items-center btn btn-link bg-white text-dark"
                    onClick={() => console.log(`Align Top for card ${index}`)}
                  >
                    <FiAlignJustify className="me-2" />
                  </button>
                  <button
                    className="d-flex align-items-center btn btn-link bg-white text-dark"
                    onClick={() => console.log(`Align Middle for card ${index}`)}
                  >
                    <FiAlignJustify className="me-2" />
                  </button>
                  <button
                    className="d-flex align-items-center btn btn-link bg-white text-dark"
                    onClick={() => console.log(`Align Bottom for card ${index}`)}
                  >
                    <FiAlignJustify className="me-2" />
                  </button>
                  <button
                    className="d-flex align-items-center btn btn-link bg-white text-danger"
                    onClick={() => {
                      deleteColumnFromClumnGroup(blockId, content.id, column.id)
                    }}
                  >
                    <FiTrash2 className="" />
                  </button>
                </div>
              )}

              {/* Card Content */}

              <Droppable
                droppableId={dropable_id}

              >
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="list-group"

                  >
                    <div className="card pb-2 d-flex " style={{ backgroundColor: "transparent", border: "none" }}>

                      <div className="card-body p-0 d-flex flex-column" style={{ backgroundColor: "transparent" }}>
                        {/* <h5 className="card-title">{column.title}</h5>
                <p className="card-text">{column.content}</p> */}
                        {
                          column.content.map((itm, itmIndex) => {

                            console.log("itm", itm)

                            return (
                              <Draggable
                                key={itm.id}
                                draggableId={itm.id}
                                index={itmIndex}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={` rounded-lg ${snapshot.isDragging
                                      ? 'bg-blue-100 shadow-lg'
                                      : 'bg-transparent text-white hover:bg-gray-100'
                                      } transition-colors duration-200`}
                                  >

                                    {itm.type === "TEXT" ?
                                      <TextEditor2 blockId={blockId} content={itm} contentId={content.id} columnId={column.id} /> :
                                      itm.type === columns ? <HorizontalButtonList
                                        blockId={blockId}
                                        content={itm}
                                        key={itm.id}
                                      /> : null}

                                  </div>
                                )}
                              </Draggable>

                            )
                          })
                        }
                        {/* <div className="d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center" style={{ backgroundColor: 'turquoise', borderRadius: '50%', height: '30px', width: '30px', cursor: 'pointer' }}><FiPlusCircle className="text-white" size={20} /></div>
                        </div>
                        <div className="mt-auto">
                          <button
                            className="btn btn-danger"
                            onClick={() => removeColumn(index)}
                          >
                            Remove
                          </button>
                        </div> */}
                      </div>
                    </div>

                    {provided.placeholder}
                  </div>
                )}

              </Droppable>

            </div>
          )
        }
        )
        }
      </div>
    </div>
  );
};

export default Columns;