import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const FruitsSidebar = () => {
    const sidebarFruits = [
        { id: 'fruit-1', content: '🍎 Apple' },
        { id: 'fruit-2', content: '🍌 Banana' },
        { id: 'fruit-3', content: '🍊 Orange' },
        { id: 'fruit-4', content: '🍇 Grape' },
        { id: 'fruit-5', content: '🍐 Pear' }
    ];

    return (
        <div className="card" style={{ width: '18rem' }}>
            <div className="card-header">
                <h5 className="card-title">Fruits</h5>
            </div>
            <div className="card-body">
                <Droppable droppableId="sidebar" isDropDisabled={true}>
                    {(provided) => (
                        <div 
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="list-group"
                        >
                            {sidebarFruits.map((item, index) => (
                                <Draggable 
                                    key={item.id} 
                                    draggableId={item.id} 
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={`list-group-item ${snapshot.isDragging ? 'list-group-item-primary' : ''}`}
                                        >
                                            {item.content}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </div>
    );
};

const BlocksContainer = () => {
    const [blocks, setBlocks] = useState({
        'block-1': {
            id: 'block-1',
            title: 'Block 1',
            items: [
                { id: 'fruit-43565', content: '🍎 Apple' },
                { id: 'fruit-678798', content: '🍎 Apple' },
                { id: 'fruit-45976657', content: '🍎 Apple' },
                { id: 'fruit-457567567', content: '🍎 Apple' },
                { id: 'fruit-4576575675', content: '🍎 Apple' },
 

            ]
        },
        'block-2': {
            id: 'block-2',
            title: 'Block 2',
            items: [
                { id: 'fruit-98', content: '🍎 Apple' },
                { id: 'fruit-90', content: '🍌 Banana' }
            ]
        },
        'block-3': {
            id: 'block-3',
            title: 'Block 3',
            items: [
                { id: 'fruit-567', content: '🍐 Pear' },
                { id: 'fruit-754', content: '🍎 Apple' }
            ]
        }
    });

    const handleDragEnd = (result) => {
        const { source, destination } = result;

        // Dropped outside a droppable area
        if (!destination) return;

        // If source is sidebar, create a copy
        if (source.droppableId === 'sidebar') {
            const newItem = {
                id: `${result.draggableId}-${Date.now()}`,
                content: result.draggableId.includes('apple') ? '🍎 Apple' :
                        result.draggableId.includes('banana') ? '🍌 Banana' :
                        result.draggableId.includes('orange') ? '🍊 Orange' :
                        result.draggableId.includes('grape') ? '🍇 Grape' : '🍐 Pear'
            };

            setBlocks(prevBlocks => {
                const destItems = Array.from(prevBlocks[destination.droppableId].items);
                destItems.splice(destination.index, 0, newItem);

                return {
                    ...prevBlocks,
                    [destination.droppableId]: {
                        ...prevBlocks[destination.droppableId],
                        items: destItems
                    }
                };
            });
            return;
        }

        // Moving between blocks or within the same block
        setBlocks(prevBlocks => {
            const sourceItems = Array.from(prevBlocks[source.droppableId].items);
            const destItems = source.droppableId === destination.droppableId 
                ? sourceItems 
                : Array.from(prevBlocks[destination.droppableId].items);

            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);

            return {
                ...prevBlocks,
                [source.droppableId]: {
                    ...prevBlocks[source.droppableId],
                    items: sourceItems
                },
                [destination.droppableId]: {
                    ...prevBlocks[destination.droppableId],
                    items: destItems
                }
            };
        });
    };

    return (
        <div className="row">
            {Object.values(blocks).map((block) => (
                <div key={block.id} className="col-md-4">
                    <div className="card mb-3">
                        <div className="card-header">
                            <h5 className="card-title">{block.title}</h5>
                        </div>
                        <div className="card-body">
                            <Droppable droppableId={block.id}>
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className={`list-group ${snapshot.isDraggingOver ? 'border border-primary' : ''}`}
                                        style={{ minHeight: '150px' }}
                                    >
                                        {block.items.map((item, index) => (
                                            <Draggable
                                                key={item.id}
                                                draggableId={item.id}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={`list-group-item ${snapshot.isDragging ? 'list-group-item-primary' : ''}`}
                                                    >
                                                        {item.content}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

const App = () => {
  const handleDragEnd=(result)=>{

    console.log("result",result)

  }
    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <div className="container mt-4">
                <div className="row">
                    <div className="col-md-4">
                        <FruitsSidebar />
                    </div>
                    <div className="col-md-8">
                        <BlocksContainer />
                    </div>
                </div>
            </div>
        </DragDropContext>
    );
};

export default App;
