import { useEffect, useRef } from 'react';

const TextEditor = ({ content }) => {
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.innerHTML = content.data.text;
    }
  }, [content.data.text]);

  return (
    <div>
      {/* Editor Content */}
      <div className="position-relative">
        <div
          ref={editorRef}
          className="form-control border-0 shadow-none h-auto bg-transparent px-3"
        />
      </div>

      <style>
        {
          `.focused-container {
            outline: 1px solid #5fa1ff;
          }
          .focused-container .container-hover-border,
          .focused-container .container-three-dot-menu,
          .focused-container .card-plus-menu-icon {
            display: flex !important;
          }
          h1, h2, h3, h4, h5, h6, p {
            margin: 0;
          }
          container:not(:first-child){
            margin-top: 0;
          }`
        }
      </style>
    </div>
  );
};

export default TextEditor;
