// import React, { useState } from 'react';
// import { Plus, Edit2, Save, X, Menu } from 'lucide-react';

// const EditableNavbar = () => {
//   const [isEditing, setIsEditing] = useState(false);
//   const [brand, setBrand] = useState('Your Brand');
//   const [navItems, setNavItems] = useState([
//     { id: 1, text: 'Home', link: '#' },
//     { id: 2, text: 'About', link: '#' },
//     { id: 3, text: 'Services', link: '#' }
//   ]);
//   const [actionButton, setActionButton] = useState({
//     text: 'Login',
//     link: '#',
//     isEditing: false
//   });
//   const [editingId, setEditingId] = useState(null);
//   const [showMobileMenu, setShowMobileMenu] = useState(false);
//   const [hoveredItem, setHoveredItem] = useState(null);

//   const addNavItem = () => {
//     const newItem = {
//       id: Date.now(),
//       text: 'New Link',
//       link: '#'
//     };
//     setNavItems([...navItems, newItem]);
//     setEditingId(newItem.id);
//     setIsEditing(true);
//   };

//   const updateNavItem = (id, newText, newLink) => {
//     setNavItems(navItems.map(item =>
//       item.id === id ? { ...item, text: newText, link: newLink } : item
//     ));
//   };

//   const deleteNavItem = (id) => {
//     setNavItems(navItems.filter(item => item.id !== id));
//   };

//   const navbarStyle = {
//     background: 'rgba(255, 255, 255, 0.95)',
//     backdropFilter: 'blur(10px)',
//     boxShadow: '0 2px 15px rgba(0, 0, 0, 0.08)'
//   };

//   const buttonStyle = {
//     background: 'linear-gradient(45deg, #2196F3, #21CBF3)',
//     border: 'none',
//     padding: '8px 20px',
//     transition: 'all 0.3s ease'
//   };

//   const addButtonStyle = {
//     width: '32px',
//     height: '32px',
//     borderRadius: '50%',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     background: 'linear-gradient(45deg, #2196F3, #21CBF3)',
//     border: 'none',
//     cursor: 'pointer',
//     transition: 'all 0.2s ease',
//     opacity: 0
//   };

//   return (
//     <nav className="navbar navbar-expand-lg sticky-top" style={navbarStyle}>
//       <div className="container">
//         {/* Brand Section */}
//         <div className="d-flex align-items-center position-relative"
//           onMouseEnter={() => setHoveredItem('brand')}
//           onMouseLeave={() => setHoveredItem(null)}>
//           {isEditing ? (
//             <input
//               type="text"
//               className="form-control form-control-sm me-2"
//               value={brand}
//               onChange={(e) => setBrand(e.target.value)}
//               style={{ maxWidth: '150px' }}
//             />
//           ) : (
//             <>
//               <a className="navbar-brand fw-bold" href="#" style={{ color: '#2196F3' }}>
//                 {brand}
//               </a>
//               <button
//                 className="btn btn-sm p-1 ms-2 d-flex align-items-center position-absolute end-0 top-50 translate-middle-y"
//                 onClick={() => setIsEditing(!isEditing)}
//                 style={{
//                   color: '#2196F3',
//                   opacity: hoveredItem === 'brand' ? 1 : 0,
//                   transition: 'opacity 0.2s ease',
//                   transform: 'translateX(100%)'
//                 }}
//               >
//                 <Edit2 size={16} />
//               </button>
//             </>
//           )}
//         </div>

//         {/* Mobile Toggle Button */}
//         <button
//           className="navbar-toggler border-0 shadow-none"
//           type="button"
//           onClick={() => setShowMobileMenu(!showMobileMenu)}
//         >
//           <Menu size={24} style={{ color: '#2196F3' }} />
//         </button>

//         {/* Navigation Items */}
//         <div className={`collapse navbar-collapse ${showMobileMenu ? 'show' : ''}`}>
//           <ul className="navbar-nav ms-auto align-items-lg-center">
//             {navItems.map(item => (
//               <li key={item.id}
//                 className="nav-item mx-lg-2 my-2 my-lg-0 position-relative"
//                 onMouseEnter={() => setHoveredItem(item.id)}
//                 onMouseLeave={() => setHoveredItem(null)}>
//                 {editingId === item.id ? (
//                   <div className="d-flex align-items-center">
//                     <input
//                       type="text"
//                       className="form-control form-control-sm me-2"
//                       value={item.text}
//                       onChange={(e) => updateNavItem(item.id, e.target.value, item.link)}
//                       style={{ width: '120px' }}
//                     />
//                     <div className="d-flex gap-1">
//                       <button
//                         className="btn btn-sm p-1"
//                         onClick={() => setEditingId(null)}
//                         style={{ color: '#2196F3' }}
//                       >
//                         <Save size={16} />
//                       </button>
//                       <button
//                         className="btn btn-sm p-1"
//                         onClick={() => deleteNavItem(item.id)}
//                         style={{ color: '#dc3545' }}
//                       >
//                         <X size={16} />
//                       </button>
//                     </div>
//                   </div>
//                 ) : (
//                   <div className="d-flex align-items-center">
//                     <a className="nav-link" href={item.link} style={{ color: '#666' }}>
//                       {item.text}
//                     </a>
//                     <button
//                       className="btn btn-sm p-1 ms-1"
//                       onClick={() => setEditingId(item.id)}
//                       style={{
//                         color: '#2196F3',
//                         opacity: hoveredItem === item.id ? 1 : 0,
//                         transition: 'opacity 0.2s ease'
//                       }}
//                     >
//                       <Edit2 size={14} />
//                     </button>
//                   </div>
//                 )}
//               </li>
//             ))}

//             {/* Add New Link Button */}
//             <li className="nav-item ms-lg-2 my-2 my-lg-0"
//               onMouseEnter={() => setHoveredItem('add')}
//               onMouseLeave={() => setHoveredItem(null)}>
//               <button
//                 className="btn p-0"
//                 onClick={addNavItem}
//                 style={{
//                   ...addButtonStyle,
//                   opacity: hoveredItem === 'add' ? 1 : 0
//                 }}
//               >
//                 <Plus size={20} color="white" />
//               </button>
//             </li>
//           </ul>
//         </div>
//       </div>
//       <style>
//         {`
//           /* Custom CSS to fix the mobile menu */
// .navbar-collapse {
//   background-color: rgba(255, 255, 255, 0.95); /* Match the navbar background */
//   backdrop-filter: blur(10px); /* Match the navbar backdrop filter */
//   box-shadow: 0 2px 15px rgba(0, 0, 0, 0.08); /* Match the navbar box shadow */
//   padding: 10px; /* Add some padding */
//   border-radius: 8px; /* Optional: Add border radius */
// }

// .navbar-toggler:focus {
//   outline: none; /* Remove the default focus outline */
// }

// .navbar-toggler {
//   z-index: 1000; /* Ensure the toggle button is above the mobile menu */
// }
//   .navbar-collapse.show{
//     background: #ffffff !important;
//   }
//     @media (max-width: 990px) {
//       .navbar-nav{
//           margin-top: -25px;
//       }
//         `}
//       </style>
//     </nav>
//   );
// };

// export default EditableNavbar;


// EditableNavbar.js
import React, { useState } from 'react';
import { Plus, Edit2, Save, X, Menu } from 'lucide-react';
import { WebBuilderContext } from '../../../../contexts/context/webBuilderContext';
import useWebBuilderActions from '../../../../contexts/actions/useWebBuilderActions';

const EditableNavbar = () => {




  const { blockData } = React.useContext(WebBuilderContext);

  const { setBrand,
    addLink,
    updateLink,
    deleteLink,
    setEditing,
    toggleMobileMenu } = useWebBuilderActions();



  const [hoveredItem, setHoveredItem] = useState(null);

  const navbarStyle = {
    background: 'rgba(255, 255, 255, 0.95)',
    backdropFilter: 'blur(10px)',
    boxShadow: '0 2px 15px rgba(0, 0, 0, 0.08)'
  };

  const addButtonStyle = {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(45deg, #2196F3, #21CBF3)',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    opacity: 0
  };

  return (
    <nav className="navbar navbar-expand-lg sticky-top" style={navbarStyle}>
      <div className="container">
        {/* Brand Section */}
        <div className="d-flex align-items-center position-relative"
          onMouseEnter={() => setHoveredItem('brand')}
          onMouseLeave={() => setHoveredItem(null)}>
          {blockData.navbar.editingId === 'brand' ? (
            <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control form-control-sm me-2"
                value={blockData.navbar.brand}
                onChange={(e) => setBrand(e.target.value)}
                style={{ maxWidth: '150px' }}
              />
              <button
                className="btn btn-sm p-1"
                onClick={() => setEditing(null)}
                style={{ color: '#2196F3' }}
              >
                <Save size={16} />
              </button>
            </div>
          ) : (
            <>
              <a className="navbar-brand fw-bold" href="#" style={{ color: '#2196F3' }}>
                {blockData.navbar.brand}
              </a>
              <button
                className="btn btn-sm p-1 ms-2 d-flex align-items-center position-absolute end-0 top-50 translate-middle-y"
                onClick={() => setEditing('brand')}
                style={{
                  color: '#2196F3',
                  opacity: hoveredItem === 'brand' ? 1 : 0,
                  transition: 'opacity 0.2s ease',
                  transform: 'translateX(100%)'
                }}
              >
                <Edit2 size={16} />
              </button>
            </>
          )}
        </div>

        {/* Mobile Toggle Button */}
        <button
          className="navbar-toggler border-0 shadow-none"
          type="button"
          onClick={toggleMobileMenu}
        >
          <Menu size={24} style={{ color: '#2196F3' }} />
        </button>

        {/* Navigation Items */}
        <div className={`collapse navbar-collapse ${blockData.navbar.showMobileMenu ? 'show' : ''}`}>
          <ul className="navbar-nav ms-auto align-items-lg-center">
            {blockData.navbar.links.map(item => (
              <li key={item.id}
                className="nav-item mx-lg-2 my-2 my-lg-0 position-relative"
                onMouseEnter={() => setHoveredItem(item.id)}
                onMouseLeave={() => setHoveredItem(null)}>
                {blockData.navbar.editingId === item.id ? (
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      className="form-control form-control-sm me-2"
                      value={item.text}
                      onChange={(e) => updateLink(item.id, { text: e.target.value })}
                      style={{ width: '120px' }}
                    />
                    <div className="d-flex gap-1">
                      <button
                        className="btn btn-sm p-1"
                        onClick={() => setEditing(null)}
                        style={{ color: '#2196F3' }}
                      >
                        <Save size={16} />
                      </button>
                      <button
                        className="btn btn-sm p-1"
                        onClick={() => deleteLink(item.id)}
                        style={{ color: '#dc3545' }}
                      >
                        <X size={16} />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <a className="nav-link" href={item.href} style={{ color: '#666' }}>
                      {item.text}
                    </a>
                    <button
                      className="btn btn-sm p-1 ms-1"
                      onClick={() => setEditing(item.id)}
                      style={{
                        color: '#2196F3',
                        opacity: hoveredItem === item.id ? 1 : 0,
                        transition: 'opacity 0.2s ease'
                      }}
                    >
                      <Edit2 size={14} />
                    </button>
                  </div>
                )}
              </li>
            ))}

            {/* Add New Link Button */}
            <li className="nav-item ms-lg-2 my-2 my-lg-0"
              onMouseEnter={() => setHoveredItem('add')}
              onMouseLeave={() => setHoveredItem(null)}>
              <button
                className="btn p-0"
                onClick={() => addLink({ text: 'New Link', href: '#' })}
                style={{
                  ...addButtonStyle,
                  opacity: hoveredItem === 'add' ? 1 : 0
                }}
              >
                <Plus size={20} color="white" />
              </button>
            </li>
          </ul>
        </div>
      </div>

      <style>
        {`
          .navbar-collapse {
            background-color: rgba(255, 255, 255, 0.95);
            backdrop-filter: blur(10px);
            box-shadow: 0 2px 15px rgba(0, 0, 0, 0.08);
            padding: 10px;
            border-radius: 8px;
          }

          .navbar-toggler:focus {
            outline: none;
          }

          .navbar-toggler {
            z-index: 1000;
          }

          .navbar-collapse.show {
            background: #ffffff !important;
          }

          @media (max-width: 990px) {
            .navbar-nav {
              margin-top: -25px;
            }
          }
        `}
      </style>
    </nav>
  );
};

export default EditableNavbar;