import React, { useState, useEffect } from "react";

const API_URL = process.env.REACT_APP_API_URL;

const SingleImageEditor = ({ content }) => {
    const [imageData, setImageData] = useState({
        src: "",
        alt: "",
    });

    useEffect(() => {
        if (content.image) {
            const { src, alt } = content.image;
            setImageData({
                src,
                alt: alt || "",
            });
        }
    }, [content.image]);

    return (
        <div className="container position-relative py-4">
            <div className="container-hover-border d-none position-absolute w-100 h-100 border border-2 border-white"
                style={{ top: 0, left: 0, pointerEvents: "none", zIndex: 5 }}>
            </div>

            <div className="text-center mb-4">
                {imageData.src && (
                    <div className="d-inline-block position-relative" style={{
                        maxWidth: "560px", maxHeight: "315px"
                    }}>
                        <img
                            src={imageData.src.startsWith('http') ? imageData.src : `${API_URL}/web-builder/${imageData.src}`}
                            alt={imageData.alt}
                            style={{
                                maxWidth: "100%",
                                height: "auto",
                                borderRadius: "8px",
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default SingleImageEditor;
