// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { Container, Button, Form, Card } from 'react-bootstrap';
// import { IoClose,  IoLogoWechat } from 'react-icons/io5';
// import Draggable from 'react-draggable';
// import { RiRobot2Fill } from "react-icons/ri";
// import { RiRobot3Line } from "react-icons/ri";
// import { API_URL } from "../../BackendApi/Api/config";
// import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
// import axios from 'axios';
// import { IoMic, IoMicOff, IoSend } from 'react-icons/io5';

// const ChatBot = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [message, setMessage] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [position, setPosition] = useState({ x: 0, y: 0 });
//   const [isListening, setIsListening] = useState(false);
//   const [wakeWord, setWakeWord] = useState('hello alexa');
//   const recognitionRef = useRef(null);
//   const [isDragging, setIsDragging] = useState(false);
//   const [initialPos, setInitialPos] = useState(null);
//   const [ai_preambleData, setAi_preambleData] = useState(null);
//   const [error, setError] = useState(null);
//   const [chatHistory, setChatHistory] = useState([
//     {
//       message: "Hey there! Can I assist you today? Do you want some assistance with brand or social media posts?",
//       role: "CHATBOT"
//     },

//   ]);
//   const [lastTranscriptTime, setLastTranscriptTime] = useState(null);
//   const {
//     transcript,
//     listening,
//     resetTranscript,
//     browserSupportsSpeechRecognition,
//     isMicrophoneAvailable
//   } = useSpeechRecognition();


//   useEffect(() => {
//     if (transcript && listening) {
//       const transcriptLower = transcript.toLowerCase();

//       if (transcriptLower.includes('hello alexa')) {
//         handleOpen();
//       } else if (transcriptLower.includes('shut down')) {
//         handleClose();
//       }
//       setLastTranscriptTime(new Date());

//       const timeoutId = setTimeout(() => {
//         const timeSinceLastTranscript = new Date() - lastTranscriptTime;
//         if (timeSinceLastTranscript > 1500 && transcript.trim()) {
//           handleSendMessage(transcript);
//           resetTranscript();
//         }
//       }, 1500);

//       return () => clearTimeout(timeoutId);
//     }
//   }, [transcript, listening]);
//   const speakResponse = (text) => {
//     window.speechSynthesis.cancel();
//     const utterance = new SpeechSynthesisUtterance(text);
//     utterance.lang = 'en-US';
//     window.speechSynthesis.speak(utterance);
//   };

//   const handleOpen = () => {
//     setIsOpen(true);
//     speakResponse("Hey there! Can I assist you today?");
//   };

//   const handleClose = () => {
//     window.speechSynthesis.cancel();
//     setIsOpen(false);
//   };
//   // Speech recognition initialization
//   const initializeSpeechRecognition = useCallback(() => {
//     if (browserSupportsSpeechRecognition) {
//       SpeechRecognition.startListening({
//         continuous: true,
//         language: 'en-US'
//       });
//     }
//   }, [listening, transcript, isOpen]);

//   const checkWakeWord = useCallback((transcript) => {
//     const normalizedTranscript = transcript.toLowerCase().trim();
//     const normalizedWakeWord = wakeWord.toLowerCase().trim();
//     console.log(`Transcript: ${normalizedTranscript}`);
//     return normalizedTranscript.includes(normalizedWakeWord) ||
//       normalizedTranscript.startsWith(normalizedWakeWord);
//   }, [wakeWord]);

//   useEffect(() => {
//     navigator.mediaDevices.getUserMedia({ audio: true }).catch((error) => {
//       console.error('Microphone access error:', error);
//       setError('Microphone access is required for this feature.');
//     });

//     const recognition = initializeSpeechRecognition();
//     if (recognition) {
//       recognitionRef.current = recognition;

//       recognition.onresult = (event) => {
//         const transcript = Array.from(event.results)
//           .map(result => result[0].transcript)
//           .join(' ')
//           .toLowerCase();

//         console.log('Detected speech:', transcript);

//         if (checkWakeWord(transcript)) {
//           console.log('Wake word detected!');
//           handleWakeWordDetected(transcript);
//         }
//       };

//       recognition.onerror = (event) => {
//         console.error('Speech recognition erroruseed:', event.error);
//         recognition.continuous = true;
//         setError(`Speech Recognition Error: ${event.error}`);
//         setIsListening(false);
//       };

//       recognition.onend = () => {
//         if (listening) {
//           try {
//             recognition.start();
//           } catch (err) {
//             console.error('Error restarting recognition:', err);
//             setIsListening(false);
//           }
//         }
//       };

//       try {
//         recognition.start();
//         setIsListening(true);
//       } catch (err) {
//         console.error('Error starting recognition:', err);
//         setIsListening(false);
//       }
//     }

//     return () => {
//       if (recognitionRef.current) {
//         recognitionRef.current.stop();
//       }
//     };
//   }, [initializeSpeechRecognition, checkWakeWord, listening]);


//   const handleWakeWordDetected = async (transcript) => {
//     if (recognitionRef.current) {
//       recognitionRef.current.stop(); // Stop the speech recognition when the wake word is detected
//     }

//     // Show assistant greeting
//     setIsOpen(true);

//     // Check if the chat history already has more than one entry
//     // if (chatHistory.length <= 1) {
//       const greetingMessage = "Hey there! Can I assist you today? Do you want some assistance with brand or social media posts?";
//     speakResponse(greetingMessage);
//     // }
//   };


//   const handleSendMessage = async (messageText) => {
//     let checkText = messageText?.toLowerCase();

//     // Handle special commands
//     if (checkText === "hello, alexa."|| checkText === "hello alexa") {
//       messageText = "";
//    handleOpen();
//     } else if (checkText === "shut down" || checkText === "shutdown") {
//       messageText = "";
//       handleClose();
//       return;
//     }
//       if (!messageText.trim()) return;

// const newMessage = { message: messageText, role: 'USER', timestamp: new Date().toISOString() };
// setChatHistory((prevHistory) => [...prevHistory, newMessage]);
// setLoading(true);
//       try {
//         const result = await axios.post(
//           'https://api.cohere.com/v1/chat',
//           {
//             message: messageText,
//             chat_history: chatHistory,
//             model: "command-r-08-2024",
//             preamble: ai_preambleData?.map((item) => item?.ai_preamble).join(' ')
//           },
//           {
//             headers: {
//               'Accept': 'application/json',
//               'Content-Type': 'application/json',
//               'Authorization': 'Bearer fLxSLabc6XoYUnxMV83fVYaz5ZGRzJSO5hA0a7v1'
//             }
//           }
//         );


//         let dataToFilter = result.data.chat_history ? result.data.chat_history[result.data.chat_history?.length - 1].message : '';


//         console.log("result data", dataToFilter);
//         speakResponse(dataToFilter);
//         setChatHistory([...result.data.chat_history]);
//         setMessage(''); // Clear text input after sending
//       } catch (error) {
//         console.error("Error fetching chat response:", error);
//         setChatHistory(prev => [
//           ...prev,
//           newMessage,
//           {
//             message: "Sorry, I encountered an error. Please try again.",
//             role: "CHATBOT",
//             timestamp: new Date().toISOString()
//           }
//         ]);
//         speakResponse("Sorry, I encountered an error. Please try again.");
//       } finally {
//         setLoading(false);
//       }

//   };
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     handleSendMessage(message);
//   };




//   useEffect(() => {
//     if (!browserSupportsSpeechRecognition) {
//       setError('Speech recognition is not supported in your browser. Please try Chrome.');
//       return;
//     }

//     const cleanup = async () => {
//       if (recognitionRef.current) {
//         try {
//           await SpeechRecognition.stopListening();
//           resetTranscript();
//         } catch (err) {
//           console.error('Error during cleanup:', err);
//         }
//       }
//     };

//     return () => {
//       cleanup();
//     };
//   }, [browserSupportsSpeechRecognition]);
//   const handleDragStart = () => {
//     setIsDragging(true);
//   };

//   const handleDragStop = (e, data) => {
//     setIsDragging(false);
//     setPosition({ x: data.x, y: data.y });
//   };

//   const handleButtonMouseDown = () => {
//     setInitialPos(position);
//   };

//   const handleButtonMouseUp = () => {
//     // Check if the position has changed during the drag
//     if (initialPos && initialPos.x === position.x && initialPos.y === position.y) {
//       setIsOpen(!isOpen);
//     }
//     setIsDragging(false); // Reset dragging state
//   };

//   const chatStyle = {
//     position: 'fixed',
//     bottom: '20px',
//     right: '20px',
//     zIndex: 1000,
//     width: isOpen ? '350px' : 'auto'
//   };

//   const gradientBackground = {
//     background: 'linear-gradient(135deg, #3498db, #8e44ad)',
//     border: 'none'
//   };

//   const getAiPreamble = async () => {
//     try {
//       const result = await fetch(`${API_URL}user/getAiPreamble`);
//       const res = await result.json();
//       setAi_preambleData(res.data);

//     } catch (error) {
//       alert(error.message)
//     }
//   };
//   useState(() => {
//     getAiPreamble()
//   }, [])
// const closeBtn = async() => {
//   setIsOpen(false);
// }

//   return (
//     <Draggable
//       position={position}
//       onStart={handleDragStart}
//       onStop={handleDragStop}
//       bounds="parent"
//       handle=".drag-handle"
//     >
//       <div style={chatStyle}>
//         {!isOpen ? (
//           <div className="drag-handle">
//             <Button
//               onMouseDown={handleButtonMouseDown}
//               onMouseUp={handleButtonMouseUp}
//               className="rounded-circle p-3"
//               style={{ ...gradientBackground, width: '60px', height: '60px', cursor: 'move' }}
//             >
//               {/* <IoLogoWechat size={24} color="white" /> */}
//               {/* <RiRobot2Fill size={32} color="cyan" /> */}
//               <RiRobot3Line size={32} color="white" />



//             </Button>
//           </div>
//         ) : (
//           <Card className="shadow" style={{ height: '500px' }}>
//             <Card.Header
//               style={gradientBackground}
//               className="d-flex justify-content-between align-items-center text-white py-3 drag-handle"
//             >
//               <div className="d-flex align-items-center">
//                 <div className="bg-white bg-opacity-25 rounded-circle p-2 me-2">
//                   {/* <IoLogoWechat size={24} /> */}
//                   <RiRobot3Line size={32} color="black" />

//                 </div>
//                 <div>
//                   <h5 className="mb-0">Astral AI</h5>
//                   <small className="opacity-75">Always here to help</small>
//                 </div>
//               </div>
//               <Button
//                 variant="link"
//                 className="text-white p-0"
//                   onClick={closeBtn}
//               >
//                 <IoClose size={24} />
//               </Button>
//             </Card.Header>

// <Card.Body
//   className="p-3 overflow-auto"
//   style={{ height: '360px' }}
// >
//   {chatHistory.map((chat, index) => (
//     <div
//       key={index}
//       className={`d-flex mb-3 ${chat.role === 'USER' ? 'justify-content-end' : 'justify-content-start'}`}
//     >
//       <div
//         className={`p-3 rounded-3 ${chat.role === 'USER'
//           ? 'bg-primary text-white'
//           : 'bg-light'
//           }`}
//         style={{
//           maxWidth: '85%',
//           borderBottomRightRadius: chat.role === 'USER' ? '0' : null,
//           borderBottomLeftRadius: chat.role === 'CHATBOT' ? '0' : null
//         }}
//       >
//         {(() => {
//           const message = chat.message;

//           // Split message into lines and remove empty ones
//           const lines = message.split('\n').filter(line => line.trim() !== '');

//           // Determine header (first non-list line)
//           const header = lines[0];

//           // Categorize lines into list items and bottom lines
//           const listItems = lines.filter(line =>
//             /^\d+\.\s|^[-*]\s|^[A-Za-z]/.test(line) && line !== header
//           );

//           const bottomLine = lines.find(
//             line =>
//               !listItems.includes(line) &&
//               line !== header
//           );

//           return (
//             <>
//               {/* Header */}
//               <h6>{header}</h6>

//               {/* List Items */}
//               {listItems.length > 0 && (
//                 <ul>
//                   {listItems.map((item, index) => (
//                     <li key={index}>{item.replace(/^(\d+\.\s|[-*]\s)/, '').trim()}</li>
//                   ))}
//                 </ul>
//               )}

//               {/* Bottom Line */}
//               {bottomLine && <p>{bottomLine}</p>}
//             </>
//           );
//         })()}
//       </div>
//     </div>
//   ))}
//   {loading && (
//     <div className="d-flex justify-content-start">
//       <div className="bg-light p-3 rounded-3" style={{ borderBottomLeftRadius: 0 }}>
//         <div className="d-flex gap-2">
//           <div className="spinner-grow spinner-grow-sm" role="status" />
//           <div className="spinner-grow spinner-grow-sm" role="status" />
//           <div className="spinner-grow spinner-grow-sm" role="status" />
//         </div>
//       </div>
//     </div>
//   )}
// </Card.Body>

//             <Card.Footer className="bg-white border-top-0 p-3 drag-handle">
//               <Form onSubmit={handleSubmit}>
//                 <div className="d-flex gap-2">
//                   <Form.Control
//                     type="text"
//                     value={message}
//                     onChange={(e) => setMessage(e.target.value)}
//                     placeholder="Type a message..."
//                     disabled={loading || listening}
//                   />
//                   <Button
//                     type="submit"
//                     disabled={!message.trim() || loading || listening}
//                     style={gradientBackground}
//                   >
//                     <IoSend />
//                   </Button>

//                 </div>
//               </Form>
//             </Card.Footer>
//           </Card>
//         )}
//       </div>
//     </Draggable>
//   );
// };

// const style = document.createElement('style');
// style.textContent = `
//   .drag-handle {
//     cursor: move;
//     user-select: none;
//   }

//   .drag-handle * {
//     cursor: move;
//   }
//      .pulse-animation {
//           animation: pulse 1.5s infinite;
//           color: #ff0000;
//         }

//         @keyframes pulse {
//           0% { opacity: 1; }
//           50% { opacity: 0.2; }
//           100% { opacity: 1; }
//         }
// `;
// document.head.appendChild(style);

// export default ChatBot;




import React, { useState, useEffect } from 'react';
import { Container, Button, Form, Card } from 'react-bootstrap';
import { IoClose, IoMic, IoMicOff, IoSend } from 'react-icons/io5';
import { RiRobot3Line } from "react-icons/ri";
import Draggable from 'react-draggable';
import { API_URL } from "../../BackendApi/Api/config";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import axios from 'axios';

const ChatBot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [initialPos, setInitialPos] = useState(null);
  const [ai_preambleData, setAi_preambleData] = useState(null);
  const [error, setError] = useState(null);
  const [isWakeWordDetected, setIsWakeWordDetected] = useState(false);
  const wakeWord = 'hello alexa';
  let sleepWord = ' shutdown';

  const [chatHistory, setChatHistory] = useState([
    {
      message: "Hey there! Can I assist you today? Do you want some assistance with brand or social media posts?",
      role: "CHATBOT"
    }
  ]);

  let {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const handleSpeakResponse = (response) => {
    const utterance = new SpeechSynthesisUtterance(response);
    // Add error handling
    utterance.onerror = (event) => {
      console.error("Speech synthesis error:", event.error);
    };
    // Add completion handling
    utterance.onend = () => {
      console.log("Finished speaking");
    };

    window.speechSynthesis.speak(utterance);

  };
  useEffect(() => {
    if (!browserSupportsSpeechRecognition) return;


    let normalizedTranscript = transcript.toLowerCase().trim();
    const systemSpeaking = window.speechSynthesis.speaking;
    if (systemSpeaking && chatHistory.length == 1) {
      SpeechRecognition.stopListening();
      resetTranscript();
    }

    // if (systemSpeaking && chatHistory.length > 1) {
    //   let response = chatHistory[chatHistory.length - 1].message;
    //   console.log("response:", response);
    //   console.log("transcript:", transcript);

    //   if (response.includes(transcript)) {
    //     // Find unmatched words in response (not in transcript)
    //     const unmatchedResponseWords = response
    //       .split(" ")
    //       .filter(word => !transcript.includes(word))
    //       .join(" ");

    //     console.log("Unmatched words or sentences in response:", unmatchedResponseWords);

    //     // Find extra words in transcript (not in response)
    //     const extraTranscriptWords = transcript
    //       .split(" ")
    //       .filter(word => !response.includes(word))
    //       .join(" ");

    //     console.log("Extra words in transcript:", extraTranscriptWords);
    //     transcript = extraTranscriptWords;

    //     // Stop speaking
    //     window.speechSynthesis.cancel();
    //     setTimeout(() => {
    //       if (!listening) {
    //         SpeechRecognition.startListening({ continuous: true });
    //       }
    //     }, 100);


    //   }

    // }
    // console.log("System Speaking:", systemSpeaking);





    if (!isOpen && normalizedTranscript.includes(wakeWord)) {
      setIsOpen(true);
      setIsWakeWordDetected(true);
      resetTranscript();
      if (chatHistory.length === 1) {
        const utterance = new SpeechSynthesisUtterance(
          "Hey there! Can I assist you today? Do you want some assistance with brand or social media posts?"
        );
        SpeechRecognition.stopListening(); // Stop listening while speaking
        normalizedTranscript = "";
        resetTranscript();
        utterance.onend = () => {
          SpeechRecognition.startListening({ continuous: true }); // Resume listening after speaking
        };
        window.speechSynthesis.speak(utterance);
      }
    } else if (isOpen && normalizedTranscript && isWakeWordDetected && !systemSpeaking) {

      const timeoutId = setTimeout(() => {
        handleSendMessage(normalizedTranscript);
        resetTranscript();
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [transcript]);
  // console.log(`chatHistory: ${transcript}`);

  useEffect(() => {
    if (browserSupportsSpeechRecognition) {
      SpeechRecognition.startListening({ continuous: true });
    }

    return () => {
      SpeechRecognition.stopListening();
    };
  }, []);


  const handleOpen = () => {
    setIsOpen(true);
    setIsWakeWordDetected(true);
    resetTranscript();
  }
  const handleSendMessage = async (messageText) => {
    let checkText = messageText?.toLowerCase();
    console.log(checkText);
    if (checkText === "shut down" || checkText === "shutdown" || checkText === "shut down." || checkText === "shutdown.") {
      handleClose();
      messageText = "";
      return;
    } else if (checkText === "hello alexa" || checkText === "hello, alexa.") {
      messageText = "";
      handleOpen();
      return;
    }

    if (!messageText.trim()) return;

    const newMessage = { message: messageText, role: 'USER', timestamp: new Date().toISOString() };
    setChatHistory((prevHistory) => [...prevHistory, newMessage]);
    setLoading(true);

    try {
      const response = await axios.post(
        'https://api.cohere.com/v1/chat',
        {
          message: messageText,
          chat_history: chatHistory,
          model: "command-r-08-2024",
          preamble: ai_preambleData?.map((item) => item?.ai_preamble).join(' ')
        },
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer qYUi8ZZzqXkrtqZOCZ2UBGKBgBSTe9zUcXzvdNO0'
          }
        }
      );

      setChatHistory(response.data.chat_history || []);
      if (response.data.chat_history && response.data.chat_history.length > 0) {
        const lastMessage = response.data.chat_history[response.data.chat_history.length - 1].message;

        if (lastMessage) {
          // handleSpeakResponse(lastMessage);
        }
      }
      setMessage('');
    } catch (error) {
      console.error("Error fetching chat response:", error);
      setChatHistory((prev) => [
        ...prev,
        { message: "Sorry, I encountered an error. Please try again.", role: "CHATBOT" }
      ]);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSendMessage(message);
  };



  const handleClose = () => {
    setIsOpen(false);
    setIsWakeWordDetected(false);
    resetTranscript();
    if (listening) {
      SpeechRecognition.stopListening();
    }
    SpeechRecognition.startListening({ continuous: true });
  };


  // Draggable handlers
  const handleDragStart = () => setIsDragging(true);
  const handleDragStop = (e, data) => {
    setIsDragging(false);
    setPosition({ x: data.x, y: data.y });
  };
  const handleButtonMouseDown = () => setInitialPos(position);
  const handleButtonMouseUp = () => {
    if (initialPos && initialPos.x === position.x && initialPos.y === position.y) {
      setIsOpen(!isOpen);
    }
    setIsDragging(false);
  };

  // Styles
  const chatStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: 1000,
    width: isOpen ? '350px' : 'auto'
  };

  const gradientBackground = {
    background: 'linear-gradient(135deg, #3498db, #8e44ad)',
    border: 'none'
  };

  // Get AI preamble data
  useEffect(() => {
    const fetchAiPreamble = async () => {
      try {
        const response = await axios.get(`${API_URL}user/getAiPreamble`);
        setAi_preambleData(response.data.data);
      } catch (error) {
        console.error("Error fetching AI preamble data:", error);
      }
    };
    fetchAiPreamble();
  }, []);

  return (
    <Draggable
      position={position}
      onStart={handleDragStart}
      onStop={handleDragStop}
      bounds="parent"
      handle=".drag-handle"
    >
      <div style={chatStyle}>
        {!isOpen ? (
          <div className="drag-handle">
            <Button
              onMouseDown={handleButtonMouseDown}
              onMouseUp={handleButtonMouseUp}
              className="rounded-circle p-3"
              style={{ ...gradientBackground, width: '60px', height: '60px', cursor: 'move' }}
            >
              <RiRobot3Line size={32} color="white" />
            </Button>
          </div>
        ) : (
          <Card className="shadow" style={{ height: '500px' }}>
            <Card.Header
              style={gradientBackground}
              className="d-flex justify-content-between align-items-center text-white py-3 drag-handle"
            >
              <div className="d-flex align-items-center">
                <div className="bg-white bg-opacity-25 rounded-circle p-2 me-2">
                  <RiRobot3Line size={32} color="black" />
                </div>
                <div>
                  <h5 className="mb-0">Astral AI</h5>
                  <small className="opacity-75">Always here to help</small>
                </div>
              </div>
              <Button
                variant="link"
                className="text-white p-0"
                onClick={handleClose}
              >
                <IoClose size={24} />
              </Button>
            </Card.Header>

            <Card.Body
              className="p-3 overflow-auto"
              style={{ height: '360px' }}
            >
              {chatHistory.map((chat, index) => (
                <div
                  key={index}
                  className={`d-flex mb-3 ${chat.role === 'USER' ? 'justify-content-end' : 'justify-content-start'}`}
                >
                  <div
                    className={`p-3 rounded-3 ${chat.role === 'USER'
                      ? 'bg-primary text-white'
                      : 'bg-light'
                      }`}
                    style={{
                      maxWidth: '85%',
                      borderBottomRightRadius: chat.role === 'USER' ? '0' : null,
                      borderBottomLeftRadius: chat.role === 'CHATBOT' ? '0' : null
                    }}
                  >
                    {(() => {
                      const message = chat.message;

                      // Split message into lines and remove empty ones
                      const lines = message.split('\n').filter(line => line.trim() !== '');

                      // Determine header (first non-list line)
                      const header = lines[0];

                      // Categorize lines into list items and bottom lines
                      const listItems = lines.filter(line =>
                        /^\d+\.\s|^[-*]\s|^[A-Za-z]/.test(line) && line !== header
                      );

                      const bottomLine = lines.find(
                        line =>
                          !listItems.includes(line) &&
                          line !== header
                      );

                      return (
                        <>
                          {/* Header */}
                          <h6>{header}</h6>

                          {/* List Items */}
                          {listItems.length > 0 && (
                            <ul>
                              {listItems.map((item, index) => (
                                <li key={index}>{item.replace(/^(\d+\.\s|[-*]\s)/, '').trim()}</li>
                              ))}
                            </ul>
                          )}

                          {/* Bottom Line */}
                          {bottomLine && <p>{bottomLine}</p>}
                        </>
                      );
                    })()}
                  </div>
                </div>
              ))}
              {loading && (
                <div className="d-flex justify-content-start">
                  <div className="bg-light p-3 rounded-3" style={{ borderBottomLeftRadius: 0 }}>
                    <div className="d-flex gap-2">
                      <div className="spinner-grow spinner-grow-sm" role="status" />
                      <div className="spinner-grow spinner-grow-sm" role="status" />
                      <div className="spinner-grow spinner-grow-sm" role="status" />
                    </div>
                  </div>
                </div>
              )}
            </Card.Body>


            <Card.Footer className="bg-white border-top-0 p-3 drag-handle">
              <Form onSubmit={handleSubmit}>
                <div className="d-flex gap-2">
                  <Form.Control
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type a message..."
                    disabled={loading || listening}
                  />
                  <Button
                    type="submit"
                    disabled={!message.trim() || loading || listening}
                    style={gradientBackground}
                  >
                    <IoSend />
                  </Button>

                </div>
              </Form>
            </Card.Footer>
          </Card>
        )}
      </div>
    </Draggable>
  );
};

const style = document.createElement('style');
style.textContent = `
  .drag-handle {
    cursor: move;
    user-select: none;
  }
  
  .drag-handle * {
    cursor: move;
  }
  
  .pulse-animation {
    animation: pulse 1.5s infinite;
    color: #ff0000;
  }

  @keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.2; }
    100% { opacity: 1; }
  }
`;
document.head.appendChild(style);

export default ChatBot;









