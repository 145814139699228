import React, { useContext } from "react";
import { WebBuilderContext } from "../../../../contexts/context/webBuilderContext";
import TextEditor3 from "../texteditor/TextEditor";
import HorizontalButtonList from "../buttons/ButtonsList";

const EnergyCards = ({ content, blockId }) => {
  return (
    <div
      className="container position-relative my-4 px-0"
      style={{ background: "transparent" }}
    >
      {/* Hover Border for Container */}
      <div
        className="container-hover-border d-none position-absolute w-100 h-100 border border-2 border-white"
        style={{ top: 0, left: 0, pointerEvents: "none", zIndex: 5 }}
      ></div>

      <div className="row g-4">
        {content.cards.map((card) => (
          <div
            className="col-md-4 position-relative"
            key={card.id}
            onMouseEnter={(e) => {
              e.currentTarget.querySelector(".card-hover-border").classList.remove("d-none");
            }}
            onMouseLeave={(e) => {
              e.currentTarget.querySelector(".card-hover-border").classList.add("d-none");
            }}
          >
            {/* Hover Border for Card */}
            <div
              className="card-hover-border d-none position-absolute w-100 h-100 border border-1 border-white"
              style={{ top: 0, left: 0, pointerEvents: "none", zIndex: 10 }}
            ></div>

            {/* Card Content */}
            <div className="card h-100 bg-dark text-light border border-2 border-primary" style={{ borderRadius: "1rem" }}>
              <div className="card-body d-flex flex-column">
                {card.content.map((item) => (
                  <div key={item.id}>
                    {item.type === "TEXT" ? (
                      <TextEditor3 blockId={blockId} content={item} contentId={content.id} cardId={card.id} />
                    ) : item.type === "BUTTONS" ? (
                      <HorizontalButtonList blockId={blockId} content={item} />
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EnergyCards;
