import { useState, useEffect, useRef, useContext } from 'react';
import { PlusCircle, MoreVertical, Trash2, Copy, ImageIcon, Layout, Type, Image, ImagePlus } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { io } from "socket.io-client";
import ComponentsList from './ComponentsList'
import { WebBuilderContext } from '../../contexts/context/webBuilderContext'
import GenerationLoader from "./GenerationLoader"
import useWebBuilderActions from '../../contexts/actions/useWebBuilderActions';
import axios from 'axios'
import ContentSidebar from "./ContentSidebar"
import NavbarEdit from './components/navbar/NavbarEdit';

const API_URL = process.env.REACT_APP_API_URL;

const BLOCK_TYPES = {
  NONE: { id: 'NONE', icon: Type },
  TOP: { id: 'TOP', icon: ImagePlus },
  LEFT: { id: 'LEFT', icon: Layout },
  RIGHT: { id: 'RIGHT', icon: Layout },
  BACKGROUND: { id: 'BACKGROUND', icon: Image }
};

const BlockBuilder = () => {
  const { backhalf } = useParams();

  const { genrateSections, addDragElement, addDragElementToColumn, addDragElementToCard, searchBlockImage } = useWebBuilderActions();

  const { blockData, webBuilderDispatch } = useContext(WebBuilderContext);

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  // Combined state for active menus
  const containerRef = useRef(null); // Reference for the menu container
  const menuRef = useRef(null); // Reference for the menu
  const blockContainerRef = useRef(null); // Ref to the block container

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${API_URL}/web-builder/get-web-page-by-backhalf`,
          { backhalf }, // POST data
          {
            cancelToken: source.token
          }
        );

        if (response.data.status) {

          const data = response.data.data;

          console.log("the data", data);

          if (!data.is_generated) {

            webBuilderDispatch({ type: "SET_WEBPAGE_ID", payload: data._id });

            webBuilderDispatch({ type: "SET_NAVBAR", payload: data.navbar });

            genrateSections(data.outlines, data.user_query, data._id);



          }
          else {

            webBuilderDispatch({ type: "SET_WEBPAGE_ID", payload: data._id });
            webBuilderDispatch({ type: "SET_NAVBAR", payload: data.navbar });


            webBuilderDispatch({ type: "SET_BLOCKS", payload: data.blocks });
          }

        }

        // handle your data
      } catch (error) {
        if (axios.isCancel(error)) {
          // handle cancellation
          return;
        }
        // handle other errors
      }
    };

    fetchData();

    return () => source.cancel('Operation canceled by cleanup');
  }, [backhalf]);

  // useEffect(() => {
  //   const newSocket = io(`http://localhost:5000`, {
  //     transports: ['websocket', 'polling']
  //   });

  //   newSocket.on('connect', () => {
  //     console.log('Socket connected');
  //     newSocket.emit("join-document", backhalf);
  //   });

  //   newSocket.on('connect_error', (error) => {
  //     console.error('Connection error:', error);
  //   });

  //   newSocket.on("load-document", (savedContent) => {
  //     // setContent(savedContent || "");

  //     console.log("load-document savedContent", savedContent);

  //               if (!savedContent.is_generated) {

  //           webBuilderDispatch({ type: "SET_WEBPAGE_ID", payload: savedContent._id });

  //           genrateSections(savedContent.outlines, savedContent.user_query, savedContent._id);

  //         }
  //         else {

  //           webBuilderDispatch({ type: "SET_WEBPAGE_ID", payload: savedContent._id });

  //           webBuilderDispatch({ type: "SET_BLOCKS", payload: savedContent.blocks });
  //         }
  //   });

  //   newSocket.on("receive-changes", (delta) => {
  //     // setContent(delta);
  //     webBuilderDispatch({ type: "SET_BLOCKS", payload: delta.blocks });
  //   });

  //   // setSocket(newSocket);

  //   webBuilderDispatch({ type: "SET_SOCKET", payload: newSocket });

  //   return () => newSocket.disconnect();
  // }, [backhalf]);

  const handleAddNewComponent = (newComponent) => {
    webBuilderDispatch({
      type: 'ADD_COMPONENT_TO_BLOCK',
      payload: {
        // blockId: activeBlockId, // You'll need to track the active block
        component: newComponent
      }
    });
  };

  const handleFileUpload = async (e, blockId) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsUploading(true);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append('image', file);

    try {
      const interval = setInterval(() => {
        setUploadProgress(prev => {
          if (prev >= 90) {
            clearInterval(interval);
            return 90;
          }
          return prev + 10;
        });
      }, 300);

      const response = await fetch(`${API_URL}/web-builder/upload-image`, {
        method: 'POST',
        body: formData
      });

      clearInterval(interval);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.status) {
        webBuilderDispatch({
          type: 'UPDATE_BLOCK_IMAGE',
          payload: {
            id: blockId,
            image: {
              src: data.data.filename,
              alt: file.name
            },
            webpageId: blockData.webpageId,
            socket: blockData.socket
          }
        });
      } else {
        throw new Error(data.message);
      }

      setUploadProgress(100);
      setTimeout(() => {
        setIsUploading(false);
        setUploadProgress(0);
      }, 500);

    } catch (error) {
      console.error('Upload failed:', error);
      setIsUploading(false);
      setUploadProgress(0);
      alert('Failed to upload image. Please try again.');
      e.target.value = '';
    }
  };

  const addBlock = (index) => {

    webBuilderDispatch({ type: 'ADD_BLOCK', payload: { webpageId: blockData.webpageId, socket: blockData.socket, index } });
  };

  const removeBlock = (id) => {
    webBuilderDispatch({ type: 'REMOVE_BLOCK', payload: { webpageId: blockData.webpageId, socket: blockData.socket, blockId: id } });
  };

  const duplicateBlock = (block, index) => {
    webBuilderDispatch({ type: 'DUPLICATE_BLOCK', payload: { webpageId: blockData.webpageId, socket: blockData.socket, index: index } });
  };

  const changeBlockType = (id, newType) => {

    webBuilderDispatch({ type: 'CHANGE_BLOCK_TYPE', payload: { webpageId: blockData.webpageId, socket: blockData.socket, id: id, blockType: newType } })

  };

  const handleBlockColorChange = (blockId, newColor) => {

    webBuilderDispatch({ type: 'UPDATE_BLOCK_COLOR', payload: { webpageId: blockData.webpageId, socket: blockData.socket, id: blockId, color: newColor } })
  };


  // Helper function to toggle menus
  const toggleMenu = (menuType, blockId) => {

    webBuilderDispatch({ type: 'TOGGLE_MENU', payload: { webpageId: blockData.webpageId, socket: blockData.socket, menuType, blockId } })

  };

  const handleDragEnd = (result) => {

    const { destination, draggableId, source } = result

    if (!destination) {

      return
    }

    if (source.droppableId == "sidebar-components") {

      if (draggableId) {

        const dropableData = {
          index: destination.index,
          ...JSON.parse(destination.droppableId)
        }

        console.log("the dropable data", dropableData)

        if (dropableData.type == "block") {

          const blockId = dropableData.blockId
          const index = dropableData.index
          const element = draggableId
          addDragElement(element, blockId, index)

        }

        if (dropableData.type == "columns") {

          const blockId = dropableData.blockId
          const contentId = dropableData.contentId
          const columnId = dropableData.columnId
          const index = dropableData.index
          const element = draggableId

          const data = {
            columnId: columnId,
            contentId: contentId,
            blockId: blockId,
            index: index,
            element: element
          }

          addDragElementToColumn(data)

        }

        if (dropableData.type == "cards") {

          const blockId = dropableData.blockId
          const contentId = dropableData.contentId
          const cardId = dropableData.cardId
          const index = dropableData.index
          const element = draggableId

          const data = {
            cardId: cardId,
            contentId: contentId,
            blockId: blockId,
            index: index,
            element: element
          }

          addDragElementToCard(data)

        }

      }

    } else {

      const sourceData = {
        index: source.index,
        ...JSON.parse(source.droppableId)
      }

      const destinationData = {
        index: destination.index,
        ...JSON.parse(destination.droppableId)
      }


      if (sourceData.type == "block" && destinationData.type == "block") {

        if (sourceData.blockId == destinationData.blockId) {

          const blockId = sourceData.blockId
          const sourceIndex = sourceData.index
          const destinationIndex = destinationData.index

          const payload = {

            blockId: blockId,
            sourceIndex: sourceIndex,
            destinationIndex: destinationIndex,
            webpageId: blockData.webpageId,
            socket: blockData.socket

          }

          webBuilderDispatch({ type: 'REARRANGE_BLOCK_CONTENT', payload: payload })
        }

        if (sourceData.blockId != destinationData.blockId) {

          const sourceBlockId = sourceData.blockId
          const sourceIndex = sourceData.index
          const destinationBlockId = destinationData.blockId
          const destinationIndex = destinationData.index

          const payload = {
            sourceBlockId: sourceBlockId,
            sourceIndex: sourceIndex,
            destinationBlockId: destinationBlockId,
            destinationIndex: destinationIndex
          }

          webBuilderDispatch({ type: 'MOVE_CONTENT_BETWEEN_BLOCKS', payload: payload })
        }

      }

      console.log("the source data", sourceData)
      console.log("the destination data", destinationData)

    }

    console.log("the result", result)
    // Your drag end logic here if needed at parent level
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the block container
      if (blockContainerRef.current && !blockContainerRef.current.contains(event.target)) {
        console.log('Clicked outside the blocks');
        webBuilderDispatch({ type: 'REMOVE_ACTIVE_BLOCK', payload: {} });
      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  // Close the menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) && // Ensure the click is outside the container
        menuRef.current &&
        !menuRef.current.contains(event.target) // Ensure the click is outside the menu
      ) {
        webBuilderDispatch({ type: 'CLOSE_MENU', payload: {} })
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <NavbarEdit />

      <DragDropContext onDragEnd={handleDragEnd}>
        <style>
          {`
    .mainbuilder::-webkit-scrollbar{
      width: 0px !important;
      height:0px !important;
    }
      .container{
        max-width: 1150px !important;
      }
  `}
        </style>
        <div className="mainbuilder container min-w-4xl "
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            height: "100vh"
          }}
        >
          {blockData.blocks.map((block, index) => {



            return (
              <div key={block.id}
                className="block-wrapper border border-1 border-dashed rounded-0 block-container"
                style={{
                  backgroundColor: block.color || '#ffffff',
                }}
                onClick={() => {
                  webBuilderDispatch({ type: 'UPDATE_BLOCK_ID', payload: { blockId: block.id } });
                }}>

                <div className="position-relative ">
                  <div className="position-absolute top-0 start-0 mt-2 ms-2 d-flex action-buttons" style={{ zIndex: 10 }} ref={containerRef}>
                    {/* Style Button */}
                    <button
                      onClick={() => toggleMenu('style', block.id)}
                      className={`btn btn-link ${blockData.activeMenu.type === 'style' && blockData.activeMenu.blockId === block.id ? 'text-primary' : 'text-secondary'} me-2`}
                    >
                      <Layout size={20} color='#ffffff' />
                    </button>

                    {/* More Options Button */}
                    <button
                      onClick={() => toggleMenu('options', block.id)}
                      className={`btn btn-link ${blockData.activeMenu.type === 'options' && blockData.activeMenu.blockId === block.id ? 'text-primary' : 'text-secondary'}`}
                    >
                      <MoreVertical size={20} color='#ffffff' />
                    </button>


                    {blockData.activeMenu.type === 'style' && blockData.activeMenu.blockId === block.id && (
                      <div
                        className="position-absolute bg-white shadow-sm border rounded p-3"
                        style={{ left: '0', top: '100%', width: '16rem' }}
                        ref={menuRef} // Attach ref for the menu

                      >
                        <div className="d-flex flex-column gap-3">
                          {/* Layout Selection */}
                          <div className="d-flex gap-2 justify-content-between">
                            {Object.values(BLOCK_TYPES).map((type) => {
                              const IconComponent = type.icon;
                              return (
                                <button
                                  key={type.id}
                                  onClick={() => {
                                    changeBlockType(block.id, type.id)
                                  }}
                                  className={`btn btn-sm ${block.type === type.id ? 'btn-primary' : 'btn-outline-secondary'
                                    }`}
                                >
                                  <IconComponent size={16} />
                                </button>
                              );
                            })}
                          </div>

                          {/* Background Image Option */}
                          <div>
                            <label className="form-label">Block Image</label>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => handleFileUpload(e, block.id)}
                              className="form-control"
                              disabled={isUploading}
                            />
                            {isUploading && (
                              <div className="mt-2">
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `${uploadProgress}%` }}
                                    aria-valuenow={uploadProgress}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    {uploadProgress}%
                                  </div>
                                </div>
                                <small className="text-muted mt-1 d-block">
                                  Uploading image...
                                </small>
                              </div>
                            )}
                            {block.image && (
                              <div className="mt-2">
                                <img
                                  src={block.image.src.startsWith('http') ?
                                    block.image.src :
                                    `${API_URL}/web-builder/${block.image.src}`}
                                  alt={block.image.alt || 'Block image'}
                                  className="img-thumbnail"
                                  style={{ maxHeight: '100px' }}
                                />
                              </div>
                            )}
                          </div>

                          {/* Add Block Color Option */}
                          <div>
                            <label className="form-label">Block Color</label>
                            <input
                              type="color"
                              className="form-control form-control-color"
                              value={block.color || '#ffffff'} // Default to white if no color is set
                              onChange={(e) => handleBlockColorChange(block.id, e.target.value)}
                            />
                          </div>

                          {/* Overlay Effect */}
                          <div>
                            <label className="form-label">Overlay effect</label>
                            <select className="form-select">
                              <option value="none">None</option>
                              <option value="faded">Faded</option>
                            </select>
                          </div>

                          {/* Overlay Color */}
                          <div>
                            <label className="form-label">Overlay color</label>
                            <input type="color" className="form-control form-control-color" />
                          </div>

                          {/* Full-Bleed Toggle */}
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`fullBleedToggle-${block.id}`}
                              checked={true}
                            />
                            <label className="form-check-label" htmlFor={`fullBleedToggle-${block.id}`}>
                              Full-bleed card
                            </label>
                          </div>

                          {/* Content Alignment */}
                          <div>
                            <label className="form-label">Content alignment</label>
                            <div className="btn-group">
                              <button className="btn btn-sm btn-outline-secondary">Left</button>
                              <button className="btn btn-sm btn-outline-secondary">Center</button>
                              <button className="btn btn-sm btn-outline-secondary">Right</button>
                            </div>
                          </div>

                          {/* Card Width */}
                          <div>
                            <label className="form-label">Card width</label>
                            <div className="btn-group">
                              <button className="btn btn-sm btn-outline-secondary">M</button>
                              <button className="btn btn-sm btn-outline-secondary">L</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}


                    {/* More Options Dropdown */}
                    {blockData.activeMenu.type === 'options' && blockData.activeMenu.blockId === block.id && (
                      <div className="position-absolute bg-white shadow-sm border rounded py-1"
                        style={{ left: '0', top: '100%' }}>
                        <button
                          className="btn btn-link text-secondary d-flex align-items-center w-100 px-3"
                          onClick={() => duplicateBlock(block, index)}
                        >
                          <Copy size={16} className="me-2" /> Duplicate
                        </button>
                        <button
                          className="btn btn-link text-danger d-flex align-items-center w-100 px-3"
                          onClick={() => removeBlock(block.id)}
                        >
                          <Trash2 size={16} className="me-2" /> Delete
                        </button>
                      </div>
                    )}
                  </div>

                  <Droppable droppableId={
                    JSON.stringify({ type: "block", blockId: block.id })
                  }>
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={`min-h-40 space-y-2 rounded-lg ${snapshot.isDraggingOver ? 'bg-blue-50' : 'bg-red-100'
                          }`}
                      >

                        {

                          block.block_type === BLOCK_TYPES.TOP.id ?
                            (
                              <div className="container d-flex flex-column">
                                <div className="w-100 bg-light d-flex align-items-center justify-content-center" style={{ height: '15rem' }}>
                                  {
                                    block.image ? (
                                      <img
                                        src={block.image.src.startsWith('http') || block.image.src.startsWith('https') ?
                                          block.image.src :
                                          `${API_URL}/web-builder/${block.image.src}`}
                                        alt={block.image.alt || 'Block image'}
                                        className="mb-4"
                                        style={{ height: '100%', width: '100%', objectFit: 'cover', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}
                                      />
                                    ) :
                                      block.searching_image ? (
                                        <img
                                          src="https://ninjaas.com/cdn/shop/products/Website-Loader.gif?v=1660197117"
                                          alt={'Block image'}
                                          // className="img-thumbnail"
                                          style={{ maxHeight: '100px' }}
                                        />
                                      ) :
                                        <ImageIcon className="text-secondary" size={32} />
                                  }

                                </div>
                                <div className="">
                                  <ComponentsList blockId={block.id} content={block.content} blockIndex={index} />
                                </div>

                              </div>
                            ) :
                            block.block_type === BLOCK_TYPES.LEFT.id ?
                              (
                                <div className='container'>
                                  <div className="row g-0">
                                    <div className="col-12 col-lg-4 col-md-6 col-sm-5 bg-light d-flex align-items-center justify-content-center mt-4" style={{ height: '90vh', borderRadius: '30px' }}>
                                      {
                                        block.image ? (
                                          <img
                                            src={block.image.src.startsWith('http') || block.image.src.startsWith('https') ?
                                              block.image.src :
                                              `${API_URL}/web-builder/${block.image.src}`}
                                            alt={block.image.alt || 'Block image'}
                                            // className="img-thumbnail"
                                            style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: '15px' }}
                                          />
                                        ) :
                                          block.searching_image ? (
                                            <img
                                              src="https://ninjaas.com/cdn/shop/products/Website-Loader.gif?v=1660197117"
                                              alt={'Block image'}
                                              className="img-thumbnail"
                                              style={{ maxHeight: '100px' }}
                                            />
                                          ) :
                                            <ImageIcon className="text-secondary" size={32} />
                                      }
                                    </div>
                                    <div className="col-12 col-lg-8 col-md-6 col-sm-7 text-secondary d-flex flex-column align-items-center justify-content-center mt-5">
                                      <ComponentsList blockId={block.id} content={block.content} blockIndex={index} />

                                    </div>

                                  </div>
                                </div>

                              ) :

                              block.block_type === BLOCK_TYPES.RIGHT.id ?
                                (
                                  <div className="container">
                                    <div className="row g-0">
                                      <div className="col-lg-8 col-md-8 col-sm-6 col-12 text-secondary d-flex flex-column align-items-center justify-content-center mt-5">
                                        <ComponentsList blockId={block.id} content={block.content} blockIndex={index} />

                                      </div>

                                      <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4 bg-light d-flex align-items-center justify-content-center" style={{ height: '80vh', borderRadius: '30px' }}>
                                        {
                                          block.image ? (
                                            <img
                                              src={block.image.src.startsWith('http') || block.image.src.startsWith('https') ?
                                                block.image.src :
                                                `${API_URL}/web-builder/${block.image.src}`}
                                              alt={block.image.alt || 'Block image'}
                                              // className="img-thumbnail"
                                              style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: '15px' }}
                                            />
                                          ) :
                                            block.searching_image ? (
                                              <img
                                                src="https://ninjaas.com/cdn/shop/products/Website-Loader.gif?v=1660197117"
                                                alt={'Block image'}
                                                className="img-thumbnail"
                                                style={{ maxHeight: '100px' }}
                                              />
                                            ) :
                                              <ImageIcon className="text-secondary" size={32} />
                                        }
                                      </div>
                                    </div>
                                  </div>

                                ) :

                                block.block_type === BLOCK_TYPES.BACKGROUND.id ?
                                  (
                                    <div className="position-relative" style={{ height: 'fit-content' }}>
                                      <div className="position-absolute top-0 start-0 end-0 bottom-0 bg-dark d-flex align-items-center justify-content-center">
                                        {block.image ? (
                                          <img
                                            src={block.image.src.startsWith('http') || block.image.src.startsWith('https') ?
                                              block.image.src :
                                              `${API_URL}/web-builder/${block.image.src}`}
                                            alt={block.image.alt || 'Block image'}
                                            style={{
                                              height: '100%',
                                              width: '100%',
                                              objectFit: 'cover',
                                              filter: 'blur(2px)',
                                              opacity: '0.4' // Added opacity to darken the image
                                            }}
                                          />
                                        ) : block.searching_image ? (
                                          <img
                                            src="https://ninjaas.com/cdn/shop/products/Website-Loader.gif?v=1660197117"
                                            alt={'Block image'}
                                            className="img-thumbnail"
                                            style={{ maxHeight: '100px' }}
                                          />
                                        ) : (
                                          <ImageIcon className="text-secondary" size={32} />
                                        )}
                                      </div>
                                      <div className="p-3 text-white text-center position-relative"> {/* Changed text-secondary to text-white and added position-relative */}
                                        <ComponentsList blockId={block.id} content={block.content} blockIndex={index} />
                                      </div>
                                    </div>

                                  ) :

                                  (

                                    <div className="p-3 text-secondary text-center">
                                      <ComponentsList blockId={block.id} content={block.content} blockIndex={index} />
                                    </div>
                                  )

                        }

                        {provided.placeholder}

                      </div>
                    )}
                  </Droppable>
                </div>

                <div className="position-relative" style={{ height: '0' }}>
                  <button
                    onClick={() => addBlock(index)}
                    className="position-absolute start-50 translate-middle add-button"
                    style={{ background: 'white', bottom: '-33px' }}
                  >
                    <PlusCircle size={24} className="text-secondary hover-primary" />
                  </button>
                </div>
              </div>
            )
          })}


          {blockData.blocks.length === 0 && (
            <div className="empty-state">
              <div className="border border-2 border-dashed rounded p-4 text-secondary text-center" style={{ minHeight: '100px' }}>
                No blocks yet
                <div className="mt-3">
                  <button
                    onClick={() => addBlock()}
                    className="btn btn-outline-primary"
                  >
                    <PlusCircle size={20} className="me-2" /> Add Block
                  </button>
                </div>
              </div>
            </div>
          )}

          <style>
            {`
          .mainbuilder{
            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          }
          .block-container {
            transition: border-color 0.2s;
          }
          
          .block-container:hover {
            border-color: var(--bs-primary) !important;
          }
          
          .action-buttons {
            opacity: 0;
            transition: opacity 0.2s;
          }
          
          .block-wrapper:hover .action-buttons {
            opacity: 1;
          }
          
          .add-button {
            opacity: 0;
            transition: opacity 0.2s;
            border: none;
            padding: 0;
            margin-top: -12px;
          }
          
          .block-wrapper:hover .add-button,
          .add-button:hover {
            opacity: 1;
          }
          
          .hover-primary:hover {
            color: var(--bs-primary) !important;
          }
          
          .btn-link {
            text-decoration: none;
            padding: 0;
          }
          
          .btn-link:hover {
            color: var(--bs-primary) !important;
          }
        `}
          </style>
          <ContentSidebar
          // onAddComponent={handleAddNewComponent}
          />

          {
            blockData.isGenerating && <GenerationLoader />
          }
        </div>







      </DragDropContext>


    </>
  );
};




export default BlockBuilder;