import { v1 as uuidv1 } from "uuid";
import { v4 as uuidv4 } from "uuid";
import React, { useState, useContext } from "react";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL






export const BLOCK_TYPES = {
    NONE: { id: 'NONE', icon: 'Type' },
    TOP: { id: 'TOP', icon: 'ImagePlus' },
    LEFT: { id: 'LEFT', icon: 'Layout' },
    RIGHT: { id: 'RIGHT', icon: 'Layout' },
    BACKGROUND: { id: 'BACKGROUND', icon: 'Image' }
};



const useWebBuilderReduers = () => {



    // const updateBlocksToDatabase = async (webpageId, socket, blocks) => {
    //     if (socket) {
    //         socket.emit("send-changes", {webpageId, blocks});
    //     }
    // }

    const updateBlocks = async (webpageId, socket, blocks) => {

        try {
            const response = await axios.post(`${API_URL}/web-builder/update-blocks`, { webpageId, blocks });

            if (response.data.status) {
                console.log("Blocks updated successfully");
            }
        }
        catch (error) {
            console.log("Blocks not updated");
        }
    }

    const updateNavbar = async (webpageId, socket, navbar) => {

        try {
            const response = await axios.post(`${API_URL}/web-builder/update-navbar`, { webpageId, navbar });

            if (response.data.status) {
                console.log("Blocks updated successfully");
            }
        }
        catch (error) {
            console.log("Blocks not updated");
        }
    }





    function webBuilderReducer(state, action) {

        let newState;
        switch (action.type) {

            case 'SET_SOCKET':
                return {
                    ...state,
                    socket: action.payload
                }

            case 'SET_WEBPAGE_ID':
                return {
                    ...state,
                    webpageId: action.payload
                }

            case 'ADD_GENERATED_OUTLINES':
                return {
                    ...state,
                    outlines: action.payload.outlines
                }
            case 'CHANGE_OUTLINE_GENERATION_STATUS':
                return {
                    ...state,
                    isGeneratingOutline: action.payload.status
                }
            case 'CHANGE_GENERATION_STATUS':
                return {
                    ...state,
                    isGenerating: action.payload.status
                }
            case 'CLEAR_BLOCKS':
                return {
                    ...state,
                    blocks: []
                }

            case 'SET_BLOCKS':

                console.log("SET_BLOCKS", action.payload)
                return {
                    ...state,
                    blocks: action.payload
                }
            case 'ADD_BLOCK':
                const newBlock = {
                    id: `${Math.floor(10000000 + Math.random() * 90000000).toString()}${Date.now()}`,
                    block_type: BLOCK_TYPES.NONE.id,
                    imageUrl: '/api/placeholder/800/400',
                    color: '#ffffff',
                    content: [{
                        "id": `${Math.floor(10000000 + Math.random() * 90000000).toString()}${Date.now()}`,
                        "type": "TEXT",
                        "data": {
                            "text": "<h3>YOUR CONTENT HERE</h3>",
                        }
                    }]
                };

                if (typeof action.payload.index === 'number') {
                    const newBlocks = [...state.blocks];
                    newBlocks.splice(action.payload.index + 1, 0, newBlock);

                    newState = { ...state, blocks: newBlocks };
                    updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);

                    return newState
                }
                newState = { ...state, blocks: [...state.blocks, newBlock] };
                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;

            case 'REMOVE_BLOCK':
                newState = {
                    ...state,
                    blocks: state.blocks.filter(block => block.id !== action.payload.blockId),
                    activeMenu: { type: null, blockId: null }
                };

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState

            case 'DUPLICATE_BLOCK':
                const blockToDuplicate = state.blocks[action.payload.index];
                const duplicatedBlock = { ...blockToDuplicate, id: `${Math.floor(10000000 + Math.random() * 90000000).toString()}${Date.now()}` };
                const blocksWithDuplicate = [...state.blocks];
                blocksWithDuplicate.splice(action.payload.index + 1, 0, duplicatedBlock);

                newState = {
                    ...state,
                    blocks: blocksWithDuplicate,
                    activeMenu: { type: null, blockId: null }
                };

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState

            case 'CHANGE_BLOCK_TYPE':
                newState = {
                    ...state,
                    blocks: state.blocks.map(block =>
                        block.id === action.payload.id
                            ? { ...block, block_type: action.payload.blockType }
                            : block
                    ),
                    activeMenu: { type: null, blockId: null }
                };

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;

            case 'UPDATE_BLOCK_COLOR':
                newState = {
                    ...state,
                    blocks: state.blocks.map(block =>
                        block.id === action.payload.id
                            ? { ...block, color: action.payload.color }
                            : block
                    )
                };

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;

            case 'UPDATE_BLOCK_IMAGE':
                newState = {
                    ...state,
                    blocks: state.blocks.map(block =>
                        block.id === action.payload.id
                            ? { ...block, image: action.payload.image }
                            : block
                    )
                };

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;

            case 'UPDATE_BLOCK_TEXT':

                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        if (block.id === action.payload.blockId) {
                            const updatedContent = block.content.map(item =>
                                item.id === action.payload.contentId
                                    ? { ...item, data: { ...item.data, text: action.payload.newText } }
                                    : item
                            );
                            return { ...block, content: updatedContent };
                        }
                        return block;
                    })
                };

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;

            case "UPDATE_COLUMN_TEXT":
                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        if (block.id !== action.payload.blockId) return block;

                        const updatedContent = block.content.map(item => {
                            if (item.id !== action.payload.contentId) return item;

                            const updatedColumns = item.columns.map(column => {
                                if (column.id !== action.payload.columnId) return column;

                                const updatedColumnContent = column.content.map(contentItem => {
                                    if (contentItem.id !== action.payload.columnContentId) return contentItem;

                                    return {
                                        ...contentItem,
                                        data: {
                                            ...contentItem.data,
                                            text: action.payload.newText
                                        }
                                    };
                                });

                                return {
                                    ...column,
                                    content: updatedColumnContent
                                };
                            });

                            return {
                                ...item,
                                columns: updatedColumns
                            };
                        });

                        return {
                            ...block,
                            content: updatedContent
                        };
                    })
                };

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;


            case "UPDATE_CARD_TEXT":
                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        if (block.id !== action.payload.blockId) return block;

                        const updatedContent = block.content.map(item => {
                            if (item.id !== action.payload.contentId) return item;

                            const updatedCards = item.cards.map(card => {
                                if (card.id !== action.payload.cardId) return card;

                                const updatedCardContent = card.content.map(contentItem => {
                                    if (contentItem.id !== action.payload.cardContentId) return contentItem;

                                    return {
                                        ...contentItem,
                                        data: {
                                            ...contentItem.data,
                                            text: action.payload.newText
                                        }
                                    };
                                });

                                return {
                                    ...card,
                                    content: updatedCardContent
                                };
                            });

                            return {
                                ...item,
                                cards: updatedCards
                            };
                        });

                        return {
                            ...block,
                            content: updatedContent
                        };
                    })
                };

                console.log("UPDATE_CARD_TEXT", newState);

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;

            case 'DELETE_BLOCK_CONTENT':
                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        if (block.id === action.payload.blockId) {
                            const updatedContent = block.content.filter(item => item.id !== action.payload.contentId);
                            return { ...block, content: updatedContent };
                        }
                        return block;
                    })
                };

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;

            case 'REORDER_BLOCKS':
                return {
                    ...state,
                    blocks: action.payload.blocks
                };

            case 'ADD_INITIAL_BLOCK_IMAGE':
                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        if (block.id === action.payload.blockId) {

                            return { ...block, image: action.payload.image, initial_image_generated: true, searching_image: false };
                        }
                        return block;
                    })
                };

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;

            case 'TOGGLE_MENU':
                const { menuType, blockId } = action.payload;
                console.log(menuType, blockId);
                return {
                    ...state,
                    activeMenu: state.activeMenu.type === menuType && state.activeMenu.blockId === blockId
                        ? { type: null, blockId: null }
                        : { type: menuType, blockId }
                };

            case 'CLOSE_MENU':
                return {
                    ...state,
                    activeMenu: { type: null, blockId: null }
                };

            case 'UPDATE_BLOCK_ID':
                return {
                    ...state,
                    activeBlock: {
                        ...state.activeBlock,
                        blockId: action.payload.blockId
                    }

                };

            case 'UPDATE_CONTENT_ID':
                return {
                    ...state,
                    activeBlock: {
                        ...state.activeBlock,
                        contentId: action.payload.contentId
                    }
                }

            case "REMOVE_ACTIVE_BLOCK":
                return {
                    ...state,
                    activeBlock: {
                        blockId: null,
                        contentId: null
                    }
                }

            case "ADD_CONTENT":
                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        if (block.id === action.payload.blockId) {
                            const { newcontent, index } = action.payload;

                            console.log("this is incoming index", index);

                            let updatedContent;
                            if (index === null || index === undefined) {
                                updatedContent = [...block.content, newcontent];
                            } else {

                                updatedContent = [...block.content];
                                updatedContent.splice(index, 0, newcontent);
                            }

                            return { ...block, content: updatedContent };
                        }
                        return block;
                    })
                };
                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;

            case "ADD_CONTENT_TO_COLUMN":
                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        if (block.id === action.payload.blockId) {
                            const updatedContent = block.content.map(item => {
                                // Find the specific content item (likely the COLUMNS type)
                                if (item.id === action.payload.contentId) {
                                    // Find the specific column and add content to it
                                    const updatedColumns = item.columns.map(column => {
                                        if (column.id === action.payload.columnId) {

                                            let updatedContent

                                            if (action.payload.index === null || action.payload.index === undefined) {
                                                updatedContent = [...column.content];
                                            } else {

                                                updatedContent = [...column.content];
                                                updatedContent.splice(action.payload.index, 0, action.payload.newcontent);
                                            }

                                            return {
                                                ...column,
                                                content: updatedContent
                                            };
                                        }
                                        return column;
                                    });

                                    return {
                                        ...item,
                                        columns: updatedColumns
                                    };
                                }
                                return item;
                            });

                            console.log("this is block update", updatedContent);


                            return { ...block, content: updatedContent };
                        }

                        return block;
                    })
                };

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;


            case "ADD_CONTENT_TO_CARD":
                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        if (block.id === action.payload.blockId) {
                            const updatedContent = block.content.map(item => {
                                // Find the specific content item (likely the COLUMNS type)
                                if (item.id === action.payload.contentId) {
                                    // Find the specific column and add content to it
                                    const updatedCards = item.cards.map(card => {
                                        if (card.id === action.payload.cardId) {

                                            let updatedContent

                                            if (action.payload.index === null || action.payload.index === undefined) {
                                                updatedContent = [...card.content];
                                            } else {

                                                updatedContent = [...card.content];
                                                updatedContent.splice(action.payload.index, 0, action.payload.newcontent);
                                            }

                                            console.log("this is updated card", updatedContent);

                                            return {
                                                ...card,
                                                content: updatedContent
                                            };
                                        }
                                        return card;
                                    });

                                    return {
                                        ...item,
                                        cards: updatedCards
                                    };
                                }
                                return item;
                            });



                            return { ...block, content: updatedContent };
                        }

                        return block;
                    })
                };

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;

            case "ADD_GRID_IMAGE":
                return {
                    ...state,
                    blocks: state.blocks.map(block => {
                        if (block.id === action.payload.blockId) {

                            const updatedContent = block.content.map(item =>
                                item.id === action.payload.contentId
                                    ? {
                                        ...item,
                                        images: [...item.images, action.payload.image]
                                    }
                                    : item
                            );
                            console.log("this is block", updatedContent);
                            return { ...block, content: updatedContent };

                        }
                    })
                }


            case "UPDATE_GRID_IMAGE":

                console.log("this is action payload", action.payload);
                console.log("this is state", state);
                return {
                    ...state,
                    blocks: state.blocks.map(block => {
                        if (block.id === action.payload.blockId) {
                            const updatedContent = block.content.map(item =>
                                item.id === action.payload.contentId
                                    ? {
                                        ...item,
                                        images: item.images.map(image => image.id === action.payload.image.id ? action.payload.image : image)
                                    }
                                    : item
                            );
                            console.log("this is block", updatedContent);

                            return { ...block, content: updatedContent };

                        }
                        return block;
                    })
                };


            case "ADD_BUTTON_TO_BUTTON_LIST":
                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        // If this isn't the block we're looking for, return it unchanged
                        if (block.id !== action.payload.blockId) {
                            return block;
                        }

                        // Update the matching block
                        const updatedContent = block.content.map(item =>
                            item.id === action.payload.contentId
                                ? {
                                    ...item,
                                    buttonsList: [...(item.buttonsList || []), action.payload.newButton]
                                }
                                : item
                        );

                        return { ...block, content: updatedContent };
                    })
                };

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;

            case "UPDATE_BUTTON_TO_BUTTON_LIST":
                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        // Return unchanged block if not matching
                        if (block.id !== action.payload.blockId) {
                            return block;
                        }

                        // Update the matching block
                        const updatedContent = block.content.map(item => {
                            // Return unchanged content item if not matching
                            if (item.id !== action.payload.contentId) {
                                return item;
                            }

                            // Update the matching button in buttonsList
                            const updatedButtonsList = (item.buttonsList || []).map(button =>
                                button.id === action.payload.buttonId
                                    ? { ...button, ...action.payload.updatedButton }
                                    : button
                            );

                            return {
                                ...item,
                                buttonsList: updatedButtonsList
                            };
                        });

                        return { ...block, content: updatedContent };
                    })
                };

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;


            case "UPDATE_YOUTUBE_VIDEO":

                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        // Return unchanged block if ID doesn't match
                        if (block.id !== action.payload.blockId) {
                            return block;
                        }

                        // Update the matching block
                        const updatedContent = block.content.map(item => {
                            // Return unchanged item if ID doesn't match or type isn't YOUTUBE_VIDEO
                            if (item.id !== action.payload.contentId || item.type !== 'YOUTUBE_VIDEO') {
                                return item;
                            }

                            // Update the video data
                            return {
                                ...item,
                                video: action.payload.video
                            };
                        });

                        return { ...block, content: updatedContent };
                    })
                };

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;


            case "UPDATE_SINGLE_IMAGE":

                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        // Return unchanged block if ID doesn't match
                        if (block.id !== action.payload.blockId) {
                            return block;
                        }

                        // Update the matching block
                        const updatedContent = block.content.map(item => {
                            // Return unchanged item if ID doesn't match or type isn't YOUTUBE_VIDEO
                            if (item.id !== action.payload.contentId) {
                                return item;
                            }

                            // Update the video data
                            return {
                                ...item,
                                image: action.payload.image
                            };
                        });

                        return { ...block, content: updatedContent };
                    })
                };

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;

            case "REARRANGE_BLOCK_CONTENT":
                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        if (block.id === action.payload.blockId) {
                            const updatedContent = [...block.content]; // Copy content array
                            const [movedItem] = updatedContent.splice(action.payload.sourceIndex, 1); // Remove the item
                            updatedContent.splice(action.payload.destinationIndex, 0, movedItem); // Insert the item at the new index

                            console.log("Rearranged content:", updatedContent);
                            return { ...block, content: updatedContent };
                        }
                        return block; // Ensure you return the block even if it doesn't match
                    })
                };

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;


            case "MOVE_CONTENT_BETWEEN_BLOCKS":
                const sourceContent = state.blocks.find(block => block.id === action.payload.sourceBlockId)?.content[action.payload.sourceIndex];

                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        // Handle the source block: remove the content
                        if (block.id === action.payload.sourceBlockId) {
                            const updatedContent = [...block.content];
                            updatedContent.splice(action.payload.sourceIndex, 1); // Remove the item from the source
                            return { ...block, content: updatedContent };
                        }

                        // Handle the destination block: add the content
                        if (block.id === action.payload.destinationBlockId) {
                            const updatedContent = [...block.content];
                            // Insert the content at the destination index
                            updatedContent.splice(action.payload.destinationIndex, 0, sourceContent);
                            return { ...block, content: updatedContent };
                        }

                        // Return the block unchanged if it's not the source or destination
                        return block;
                    })
                };

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;


            case "ADD_COLUMN_TO_COLUMN_GROUP":
                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        if (block.id === action.payload.blockId) {
                            const updatedContent = block.content.map(item =>
                                item.id === action.payload.contentId
                                    ? {
                                        ...item,
                                        columns: [...item.columns, action.payload.newColumn]
                                    }
                                    : item
                            );
                            console.log("this is image block", updatedContent);
                            return { ...block, content: updatedContent };

                        }
                        return block;
                    })
                }

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;

            case "ADD_CARD_TO_CARD_GROUP":
                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        if (block.id === action.payload.blockId) {
                            const updatedContent = block.content.map(item =>
                                item.id === action.payload.contentId
                                    ? {
                                        ...item,
                                        cards: [...item.cards, action.payload.newCard]
                                    }
                                    : item
                            );
                            console.log("this is image block", updatedContent);
                            return { ...block, content: updatedContent };

                        }
                        return block;
                    })
                }

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;


            case "DELETE_COLUMN_CONTENT":
                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        // Skip blocks that don't match
                        if (block.id !== action.payload.blockId) {
                            return block;
                        }

                        // Map through content to find the columns
                        const updatedContent = block.content.map(item => {
                            // Skip content items that don't match
                            if (item.id !== action.payload.contentId) {
                                return item;
                            }

                            // Handle COLUMNS type
                            if (item.type === 'COLUMNS') {
                                const updatedColumns = item.columns.map(column => {
                                    // Skip columns that don't match
                                    if (column.id !== action.payload.columnId) {
                                        return column;
                                    }

                                    // Filter out the content to be deleted
                                    const filteredContent = column.content.filter(
                                        contentItem => contentItem.id !== action.payload.columnContentId
                                    );

                                    return {
                                        ...column,
                                        content: filteredContent
                                    };
                                });

                                return {
                                    ...item,
                                    columns: updatedColumns
                                };
                            }

                            return item;
                        });

                        return {
                            ...block,
                            content: updatedContent
                        };
                    })
                };

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;

            case "DELETE_COLUMN_FROM_COLUMN_GROUP":
                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        if (block.id === action.payload.blockId) {
                            const updatedContent = block.content.map(item =>
                                item.id === action.payload.contentId
                                    ? {
                                        ...item,
                                        columns: item.columns.filter(column => column.id !== action.payload.columnId)
                                    }
                                    : item
                            );
                            console.log("this is image block", updatedContent);
                            return { ...block, content: updatedContent };

                        }
                        return block;
                    })
                }

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;

            case "DELETE_CARD_FROM_CARD_GROUP":
                newState = {
                    ...state,
                    blocks: state.blocks.map(block => {
                        if (block.id === action.payload.blockId) {
                            const updatedContent = block.content.map(item =>
                                item.id === action.payload.contentId
                                    ? {
                                        ...item,
                                        cards: item.cards.filter(card => card.id !== action.payload.cardId)
                                    }
                                    : item
                            );
                            console.log("this is image block", updatedContent);
                            return { ...block, content: updatedContent };

                        }
                        return block;
                    })
                }

                updateBlocks(action.payload.webpageId, action.payload.socket, newState.blocks);
                return newState;


            case "SET_NAVBAR":
                newState = {
                    ...state,
                    navbar: action.payload
                };

                return newState;



            case 'NAVBAR_SET_BRAND':
                newState = {
                    ...state,
                    navbar: {
                        ...state.navbar,
                        brand: action.payload.brand
                    }
                };

                updateNavbar(action.payload.webpageId, action.payload.socket, newState.navbar);
                return newState;

            case 'NAVBAR_ADD_LINK':
                newState = {
                    ...state,
                    navbar: {
                        ...state.navbar,
                        links: [...state.navbar.links, {
                            id: Date.now(),
                            text: action.payload.link.text || 'New Link',
                            href: action.payload.link.href || '#'
                        }]
                    }

                };

                updateNavbar(action.payload.webpageId, action.payload.socket, newState.navbar);
                return newState;

            case 'NAVBAR_UPDATE_LINK':
                newState = {
                    ...state,
                    navbar: {
                        ...state.navbar,
                        links: state.navbar.links.map(link =>
                            link.id === action.payload.id
                                ? { ...link, ...action.payload.data }
                                : link
                        )
                    }

                };

                updateNavbar(action.payload.webpageId, action.payload.socket, newState.navbar);
                return newState;

            case 'NAVBAR_DELETE_LINK':
                newState = {
                    ...state,
                    navbar: {
                        ...state.navbar,
                        links: state.navbar.links.filter(link => link.id !== action.payload.id)
                    }
                };

                updateNavbar(action.payload.webpageId, action.payload.socket, newState.navbar);
                return newState;

            case 'NAVBAR_SET_EDITING':
                newState = {
                    ...state,
                    navbar: {
                        ...state.navbar,
                        editingId: action.payload.id
                    }
                };

                // updateNavbar(action.payload.webpageId, action.payload.socket, newState.navbar);
                return newState;

            case 'NAVBAR_TOGGLE_MOBILE_MENU':
                newState = {
                    ...state,
                    navbar: {
                        ...state.navbar,
                        showMobileMenu: !state.navbar.showMobileMenu
                    }
                };

                // updateNavbar(action.payload.webpageId, action.payload.socket, newState.navbar);
                return newState;


            default:
                return state;
        }
    }

    return {
        webBuilderReducer
    }


}

export default useWebBuilderReduers
