import React from 'react';

const HorizontalButtonList = ({ content }) => {
    return (
        <div className="d-flex align-items-center position-relative gap-3 my-2 pb-2 px-3 flex-wrap">
            {content.buttonsList.map((button) => (
                <div key={button.id} className="position-relative">
                    <button
                        className={`btn btn-${button.variant}`}
                        style={{
                            borderRadius: '6px',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            transition: 'transform 0.2s',
                        }}
                    >
                        {button.text}
                    </button>
                </div>
            ))}
        </div>
    );
};

export default HorizontalButtonList;
