import React, { useState, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { WebBuilderContext } from '../../../../contexts/context/webBuilderContext';
import { v4 as uuidv4 } from "uuid";
import { FiAlignJustify, FiEdit, FiMoreVertical, FiPlusCircle, FiTrash2 } from 'react-icons/fi';
import useWebBuilderActions from '../../../../contexts/actions/useWebBuilderActions';

const HorizontalButtonList = ({ blockId, content }) => {
    const { webBuilderDispatch } = useContext(WebBuilderContext);
    const { addButtonToButtonList,updateButtonToButtonList, deleteBlockContent } = useWebBuilderActions();
    const [buttons, setButtons] = useState([]);
    const [activeButton, setActiveButton] = useState(null);
    const [buttonText, setButtonText] = useState("");
    const [buttonVariant, setButtonVariant] = useState("primary");
    const [buttonUrl, setButtonUrl] = useState("");
    const [isHovered, setIsHovered] = useState(false);
      const [focusedContainerId, setFocusedContainerId] = useState(null);
        const [isMenuOpen, setIsMenuOpen] = useState(false);
    
    const variants = ['primary', 'secondary', 'success', 'danger', 'warning', 'info'];

    const handleButtonClick = (button) => {
        setActiveButton(button);
        setButtonText(button.text || "");
        setButtonVariant(button.variant || "primary");
        setButtonUrl(button.url || "");
    };

    const handleSave = () => {
        if (activeButton) {
            const updatedButton = {
                ...activeButton,
                text: buttonText,
                variant: buttonVariant,
                url: buttonUrl
            };

            updateButtonToButtonList(blockId, content.id, activeButton.id, updatedButton);
            
            // setButtons((prevButtons) =>
            //     prevButtons.map((btn) =>
            //         btn.id === activeButton.id ? updatedButton : btn
            //     )
            // );
            
            // webBuilderDispatch({ 
            //     type: 'UPDATE_BUTTON_IN_BUTTON_LIST', 
            //     payload: { 
            //         blockId, 
            //         contentId: content.id, 
            //         button: updatedButton 
            //     } 
            // });
        }
        closeEditor();
    };

    const closeEditor = () => {
        setActiveButton(null);
        setButtonText("");
        setButtonVariant("primary");
        setButtonUrl("");
    };

    const addNewButton = () => {
        const newButton = {
            id: `${uuidv4()}-${Math.floor(10000000 + Math.random() * 90000000).toString()}-${Date.now()}`,
            text: `Button ${buttons.length + 1}`,
            variant: 'primary',
            url: '',
        };
        setButtons([...buttons, newButton]);
        webBuilderDispatch({ 
            type: 'ADD_BUTTON_TO_BUTTON_LIST', 
            payload: { blockId, contentId: content.id, button: newButton } 
        });
    };

    // useEffect(() => {
    //     setButtons(content.buttonsList || []);
    // }, []);

    const handleClickInside = (e) => {
        e.stopPropagation();
        setFocusedContainerId(content.id);
        webBuilderDispatch({
          type: "UPDATE_CONTENT_ID",
          payload: { contentId: content.id },
        });
      };

    return (
        <div
        className={`d-flex align-items-center justify-content-center position-relative gap-3 my-2 pb-2 px-3 flex-wrap ${
            focusedContainerId === content.id ? "focused-container" : ""
          }`}
          onMouseEnter={(e) => {
            setIsHovered(true);
            // const containerSixDotMenu = e.currentTarget.querySelector(".container-six-dot-menu");
            const containerHoverBorder = e.currentTarget.querySelector(".container-hover-border");
            const containerThreeDotMenu = e.currentTarget.querySelector(".container-three-dot-menu");
            const cardPlusMenuIcon = e.currentTarget.querySelector(".card-plus-menu-icon");
          
            // if (containerSixDotMenu) containerSixDotMenu.classList.remove("d-none");
            if (containerHoverBorder) containerHoverBorder.classList.remove("d-none");
            if (containerThreeDotMenu) containerThreeDotMenu.classList.remove("d-none");
            if (cardPlusMenuIcon) cardPlusMenuIcon.classList.remove("d-none");
          }}
          onMouseLeave={(e) => {
            setIsHovered(false);
            // const containerSixDotMenu = e.currentTarget.querySelector(".container-six-dot-menu");
            const containerHoverBorder = e.currentTarget.querySelector(".container-hover-border");
            const containerThreeDotMenu = e.currentTarget.querySelector(".container-three-dot-menu");
            const cardPlusMenuIcon = e.currentTarget.querySelector(".card-plus-menu-icon");
          
            // if (containerSixDotMenu) containerSixDotMenu.classList.add("d-none");
            if (containerHoverBorder) containerHoverBorder.classList.add("d-none");
            if (containerThreeDotMenu) containerThreeDotMenu.classList.add("d-none");
            if (cardPlusMenuIcon) cardPlusMenuIcon.classList.add("d-none");
          }}
          
        // onClick={(e) => {
        //   webBuilderDispatch({
        //     type: "UPDATE_CONTENT_ID",
        //     payload: { contentId: content.id },
        //   }); 
        //   handleClickInside(e);
        // }}
      >
        {/* <div
          className="container-six-dot-menu d-none position-absolute p-2 bg-white shadow-sm rounded"
          style={{ zIndex: 10, top: "0px", right: "-26px" }}
        >
          <button
            className="btn btn-link text-secondary"
            onClick={(e) => {
              e.stopPropagation();
              console.log("6-dot menu clicked");
            }}
          >
            <MdOutlineDragIndicator size={20} />
          </button>
        </div> */}

        <div
                  className="container-hover-border d-none position-absolute w-100 h-100 border border-2"
                  style={{ top: 0, left: 0, pointerEvents: "none", zIndex: 5 }}
                ></div>
        
                <div
                  className="container-three-dot-menu d-none position-absolute p-2 bg-white shadow-sm rounded"
                  style={{ zIndex: 10, top: "0px", left: "-36px" }}
                >
                  <button
                    className="btn btn-link text-secondary"
                    onClick={(e) => {e.stopPropagation(); setIsMenuOpen((prev) => !prev)}}
                  >
                    <FiMoreVertical size={20} />
                  </button>
                </div>
                {isMenuOpen && (
                                <div
                                  className="d-flex align-items-center position-absolute bg-white shadow-sm rounded py-2"
                                  style={{
                                    zIndex: 15,
                                    left: "50%",
                                    right: "50%",
                                    width: "fit-content",
                                    padding: "4px 10px",
                                    transform: "translate(-5%, -120%)", // Centers the menu and positions it above
                                  }}
                                >
                                  <button
                                    className="d-flex align-items-center btn btn-link bg-white text-dark"
                            
                                  >
                                    <FiEdit className="me-2" />
                                  </button>
                                  <button
                                    className="d-flex align-items-center btn btn-link bg-white text-dark"
                    
                                  >
                                    <FiAlignJustify className="me-2" />
                                  </button>
                                  <button
                                    className="d-flex align-items-center btn btn-link bg-white text-dark"
                                
                                  >
                                    <FiAlignJustify className="me-2" />
                                  </button>
                                  <button
                                    className="d-flex align-items-center btn btn-link bg-white text-dark"
                                
                                  >
                                    <FiAlignJustify className="me-2" />
                                  </button>
                                  <button
                                    className="d-flex align-items-center btn btn-link bg-white text-danger"
                                    onClick={() => deleteBlockContent(blockId, content.id)}
                                  >
                                    <FiTrash2 className="" />
                                  </button>
                                </div>
                              )}
            {content.buttonsList.map((button) => (
                <div key={button.id} className="position-relative">
                    <button
                        className={`btn btn-${button.variant}`}
                        style={{
                            borderRadius: '6px',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            transition: 'transform 0.2s',
                        }}
                        onClick={() => handleButtonClick(button)}
                    >
                        {button.text}
                    </button>
                </div>
            ))}

            {isHovered && (
                <div
                    onClick={() => {
                      addButtonToButtonList(blockId, content.id);
                    }}
                    className="d-flex align-items-center justify-content-center" style={{ backgroundColor: '#007BFF', borderRadius: '50%', height: '30px', width: '30px', cursor: 'pointer' }}
                >
                    <FiPlusCircle className="text-white" size={16} />
                </div>
            )}

            {activeButton && ReactDOM.createPortal(
                <div
                    className="modal show fade d-block"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Button</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={closeEditor}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label className="form-label">Button Text</label>
                                    <input
                                        type="text"
                                        value={buttonText}
                                        onChange={(e) => setButtonText(e.target.value)}
                                        className="form-control"
                                        placeholder="Enter button text"
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Button URL</label>
                                    <input
                                        type="url"
                                        value={buttonUrl}
                                        onChange={(e) => setButtonUrl(e.target.value)}
                                        className="form-control"
                                        placeholder="Enter button URL"
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Button Style</label>
                                    <div className="d-flex gap-2 flex-wrap">
                                        {variants.map((variant) => (
                                            <button
                                                key={variant}
                                                type="button"
                                                onClick={() => setButtonVariant(variant)}
                                                className={`btn btn-${variant} ${
                                                    buttonVariant === variant ? 'border border-dark' : ''
                                                }`}
                                                style={{
                                                    minWidth: '60px',
                                                    borderRadius: '6px',
                                                    transition: 'transform 0.2s',
                                                    transform: buttonVariant === variant ? 'scale(1.1)' : 'scale(1)',
                                                }}
                                            >
                                                {variant}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSave}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>,
                document.body
            )}
        </div>
    );
};

export default HorizontalButtonList;