import React, { useState, useEffect } from "react";

const SingleVideoEditor = ({ content }) => {
    const [videoData, setVideoData] = useState({
        id: "",
        url: "",
        title: "",
    });

    useEffect(() => {
        if (content.video) {
            const { id, title } = content.video;
            setVideoData({
                id,
                url: `https://youtube.com/watch?v=${id}`,
                title: title || "",
            });
        }
    }, [content.video]);

    return (
        <div className="container position-relative py-4">
            <div className="text-center mb-4">
                {videoData.id && (
                    <div
                        className="d-inline-block position-relative"
                        style={{
                            width: 560,
                            height: 315,
                        }}
                    >
                        <iframe
                            src={`https://www.youtube.com/embed/${videoData.id}`}
                            title={videoData.title}
                            style={{
                                width: "100%",
                                height: "100%",
                                border: 0,
                                borderRadius: "8px",
                            }}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default SingleVideoEditor;
