import React, { useState } from 'react';
import { 
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import { 
  Type, 
  Image, 
  PlayCircle, 
  Grid, 
  MessageCircle, 
  Columns, 
  CreditCard,
  PanelLeft 
} from 'lucide-react';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import "react-pro-sidebar/dist/css/styles.css";

// Define component types with icons and display names
const COMPONENT_TYPES = [
  { 
    type: 'NORMAL_TEXT', 
    icon: Type, 
    name: 'Text' 
  },
  { 
    type: 'SINGLE_IMAGE', 
    icon: Image, 
    name: 'Single Image' 
  },
  // { 
  //   type: 'IMAGE_GRID', 
  //   icon: Grid, 
  //   name: 'Image Grid' 
  // },
  { 
    type: 'YOUTUBE_VIDEO', 
    icon: PlayCircle, 
    name: 'Youtube Video' 
  },

  { 
    type: 'COLUMNS', 
    icon: Columns, 
    name: 'Columns' 
  },
  { 
    type: 'CARDS', 
    icon: CreditCard, 
    name: 'Cards' 
  },
  { 
    type: 'BUTTON', 
    icon: Columns, 
    name: 'Buttons' 
  }
];

const ContentSidebar = ({ onAddComponent }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleAddComponent = (type) => {
    const newComponent = {
      id: `${type.toLowerCase()}_${Date.now()}`,
      type: type,
      content: {}
    };

    onAddComponent(newComponent);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // Circular toggle button styles
  const toggleButtonStyle = {
    position: 'fixed', 
    right: '1rem', 
    top: '50%', 
    transform: 'translateY(-50%)', 
    zIndex: 10000,
    width: '50px', 
    height: '50px', 
    borderRadius: '50%', 
    background: '#f0f0f0', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    cursor: 'pointer',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
  };

  return (
    <>
      {!isOpen && (
        <div style={toggleButtonStyle} onClick={toggleSidebar}>
          <PanelLeft />
        </div>
      )}
      
      <ProSidebar 
        collapsed={!isOpen}
        style={{
          position: 'fixed',
          right: isOpen ? '0' : '-300px', 
          top: '0', 
          bottom: '0',
          width: '300px',
          zIndex: 10000,
          transition: 'left 0.3s ease'
        }}
      >
        <SidebarHeader>
          <div className="d-flex justify-content-between align-items-center p-3">
            <h3 className="text-lg font-semibold mb-0">Add Components</h3>
            <button onClick={toggleSidebar} className="btn btn-link p-0">
              <PanelLeft />
            </button>
          </div>
        </SidebarHeader>
        
        <SidebarContent>
          <Droppable droppableId="sidebar-components" isDropDisabled={true}>
            {(provided) => (
              <Menu 
                {...provided.droppableProps} 
                ref={provided.innerRef}
                className="list-group"

              >
                {COMPONENT_TYPES.map((component, index) => (
                  <Draggable 
                    key={component.type} 
                    draggableId={component.type} 
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <MenuItem 
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        // onClick={() => handleAddComponent(component.type)}
                        icon={<component.icon />}
                        className='shadow-lg'
                      >
                        {component.name}
                      </MenuItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Menu>
            )}
          </Droppable>
        </SidebarContent>
      </ProSidebar>
    </>
  );
};

export default ContentSidebar;