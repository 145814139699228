import React, { useState, useContext, useEffect } from "react";
import { createPortal } from "react-dom";
import { WebBuilderContext } from '../../../../contexts/context/webBuilderContext';
import { FiAlignJustify, FiEdit, FiMoreHorizontal, FiTrash2 } from "react-icons/fi";
import useWebBuilderActions from "../../../../contexts/actions/useWebBuilderActions";

const SingleVideoEditor = ({ blockId, content }) => {
    const { webBuilderDispatch } = useContext(WebBuilderContext);
    const { deleteBlockContent, updateYoutube } = useWebBuilderActions();
    const [videoData, setVideoData] = useState({
        id: "",
        url: "",
        title: "",
        previewId: "" // Added for modal preview
    });
    const [isEditing, setIsEditing] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        if (content.video) {
            const { id, title } = content.video;
            setVideoData({
                id,
                previewId: id,
                url: `https://youtube.com/watch?v=${id}`,
                title: title || "",
            });
        }
    }, [content.video]);

    const extractVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return match && match[2].length === 11 ? match[2] : null;
    };

    const handleUrlChange = (url) => {
        const videoId = extractVideoId(url);
        setVideoData(prev => ({
            ...prev,
            url,
            previewId: videoId || "" // Update preview immediately if valid
        }));
    };

    const handleSave = () => {
        if (!videoData.previewId) {
            alert("Please enter a valid YouTube URL");
            return;
        }

        const newVideo = {
            id: videoData.previewId,
            title: videoData.title || "YouTube Video",
            url: `https://www.youtube.com/embed/${videoData.previewId}`
        };

        updateYoutube(blockId, content.id, newVideo);
        setIsEditing(false);
    };

    const MenuButtons = () => (
        <>
            <button
                className="d-flex align-items-center btn btn-link bg-white text-dark"
                onClick={() => { setIsEditing(true); }}
            >
                <FiEdit className="me-2" />
            </button>
            <button
                className="d-flex align-items-center btn btn-link bg-white text-dark"
            >
                <FiAlignJustify className="me-2" />
            </button>
            <button
                className="d-flex align-items-center btn btn-link bg-white text-dark"
            >
                <FiAlignJustify className="me-2" />
            </button>
            <button
                className="d-flex align-items-center btn btn-link bg-white text-dark"
            >
                <FiAlignJustify className="me-2" />
            </button>
            <button
                className="d-flex align-items-center btn btn-link bg-white text-danger"
                onClick={() => { deleteBlockContent(blockId, content.id); }}
            >
                <FiTrash2 className="" />
            </button>
        </>
    );

    return (
        <div
            className="container position-relative py-4"
            onMouseEnter={(e) => {
                setIsHovered(true);
                e.currentTarget.querySelector(".container-hover-border").classList.remove("d-none");
                e.currentTarget.querySelector(".container-three-dot-menu").classList.remove("d-none");
            }}
            onMouseLeave={(e) => {
                setIsHovered(false);
                e.currentTarget.querySelector(".container-hover-border").classList.add("d-none");
                e.currentTarget.querySelector(".container-three-dot-menu").classList.add("d-none");
            }}
            onClick={() => {
                webBuilderDispatch({
                    type: 'UPDATE_CONTENT_ID',
                    payload: { contentId: content.id }
                });
            }}
        >
            <div
                className="container-hover-border d-none position-absolute w-100 h-100 border border-2 border-white"
                style={{ top: 0, left: 0, pointerEvents: "none", zIndex: 5 }}
            ></div>

            <div
                className="container-three-dot-menu d-none position-absolute p-2 bg-light shadow-sm rounded"
                style={{ zIndex: 10, top: "0px", left: "-36px" }}
            >
                <button
                    className="btn btn-link text-black"
                    onClick={() => { setIsMenuOpen((prev) => !prev); }}
                >
                    <FiMoreHorizontal size={20} />
                </button>
                {isMenuOpen && (
                    <div
                        className="d-flex align-items-center position-absolute bg-white shadow-sm rounded py-2"
                        style={{
                            zIndex: 15,
                            top: "8px",
                            left: "50%",
                            right: "50%",
                            width: "fit-content",
                            padding: "4px 10px",
                            transform: "translate(-50%, -120%)",
                        }}
                    >
                        <MenuButtons />
                    </div>
                )}
            </div>

            <div className="text-center mb-4">
                {videoData.id && (
                    <div
                        className="d-inline-block position-relative"
                        style={{
                            width: 560,
                            height: 315,
                        }}
                    >
                        <iframe
                            src={`https://www.youtube.com/embed/${videoData.id}`}
                            title={videoData.title}
                            style={{
                                width: "100%",
                                height: "100%",
                                border: 0,
                                borderRadius: "8px",
                            }}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                        {isHovered && (
                            <div
                                className="position-absolute"
                                style={{
                                    top: 8,
                                    right: 8,
                                    zIndex: 1
                                }}
                            >
                                <button
                                    className="btn btn-light btn-sm"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsEditing(true);
                                    }}
                                >
                                    Edit
                                </button>
                            </div>
                        )}
                    </div>
                )}

                {isHovered && !videoData.id && (
                    <button
                        className="btn btn-primary"
                        onClick={() => setIsEditing(true)}
                    >
                        Add YouTube Video
                    </button>
                )}
            </div>

            {isEditing && createPortal(
                <div
                    className="modal show fade d-block"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {videoData.id ? "Edit Video" : "Add YouTube Video"}
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => setIsEditing(false)}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label className="form-label">YouTube URL</label>
                                    <input
                                        type="text"
                                        value={videoData.url}
                                        onChange={(e) => handleUrlChange(e.target.value)}
                                        placeholder="https://www.youtube.com/watch?v=..."
                                        className="form-control"
                                    />
                                </div>

                                {videoData.previewId && (
                                    <div className="mb-4 text-center">
                                        <iframe
                                            src={`https://www.youtube.com/embed/${videoData.previewId}`}
                                            title={videoData.title}
                                            style={{
                                                width: "100%",
                                                height: "200px",
                                                border: 0,
                                                borderRadius: "8px"
                                            }}
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        />
                                    </div>
                                )}

                                <div className="mb-3">
                                    <label className="form-label">Title</label>
                                    <input
                                        type="text"
                                        value={videoData.title}
                                        onChange={(e) => setVideoData(prev => ({ ...prev, title: e.target.value }))}
                                        className="form-control"
                                        placeholder="Video title for accessibility"
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setIsEditing(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSave}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>,
                document.body
            )}
        </div>
    );
};

export default SingleVideoEditor;