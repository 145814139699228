import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { MdOutlineDragIndicator } from "react-icons/md";
import { WebBuilderContext } from '../../../../contexts/context/webBuilderContext';
import {
  Bold, Italic, Underline, AlignLeft, AlignCenter, AlignRight,
  List, Link, Strikethrough, Code, Palette, MoreVertical, PlusCircle, Trash
} from 'lucide-react';
import useWebBuilderActions from '../../../../contexts/actions/useWebBuilderActions';


const TextEditor = ({ blockId, contentId, cardId, content }) => {
  const { webBuilderDispatch } = useContext(WebBuilderContext);
  const { deleteColumnContent, updateCardText } = useWebBuilderActions();
  const [showToolbar, setShowToolbar] = useState(false);
  const [toolbarPosition, setToolbarPosition] = useState({ top: 0, left: 0 });
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [focusedContainerId, setFocusedContainerId] = useState(null);
  const editorRef = useRef(null);
  const toolbarRef = useRef(null);
  const containerRef = useRef(null);
  const [text, setText] = useState('<p>Enter text </p>');

  const timeoutRef = useRef(null);


  // Existing constants (colors, fonts, fontSizes, tools)
  const colors = [
    '#000000', '#434343', '#666666', '#999999', '#b7b7b7', '#cccccc',
    '#d9d9d9', '#efefef', '#f3f3f3', '#ffffff', '#980000', '#ff0000',
    '#ff9900', '#ffff00', '#00ff00', '#00ffff', '#4a86e8', '#0000ff',
    '#9900ff', '#ff00ff'
  ];

  const fonts = [
    { label: 'Fonts', value: '' },
    { label: 'Default', value: 'inherit' },
    { label: 'Arial', value: 'Arial, sans-serif' },
    { label: 'Times New Roman', value: 'Times New Roman, serif' },
    { label: 'Helvetica', value: 'Helvetica, sans-serif' },
    { label: 'Georgia', value: 'Georgia, serif' },
    { label: 'Verdana', value: 'Verdana, sans-serif' },
    { label: 'Courier New', value: 'Courier New, monospace' }
  ];

  const fontSizes = [
    { label: 'Size', value: '' },
    { label: 'Default', value: '16px' },
    { label: 'Small', value: '12px' },
    { label: 'Medium', value: '14px' },
    { label: 'Large', value: '20px' },
    { label: 'X-Large', value: '24px' },
    { label: '2X-Large', value: '32px' }
  ];

  const tools = [
    {
      label: 'Normal text',
      options: ['Headings', 'Normal text', 'Heading 1', 'Heading 2', 'Heading 3']
    },
    { icon: Bold, command: 'bold' },
    { icon: Italic, command: 'italic' },
    { icon: Underline, command: 'underline' },
    { icon: Strikethrough, command: 'strikethrough' },
    { icon: Code, command: 'code' },
    { icon: Link, command: 'createLink' },
    { icon: Palette, command: 'color' },
    { icon: AlignLeft, command: 'justifyLeft' },
    { icon: AlignCenter, command: 'justifyCenter' },
    { icon: AlignRight, command: 'justifyRight' },
    { icon: List, command: 'insertUnorderedList' }
  ];

  const debouncedUpdate = useCallback((newText) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      updateCardText(blockId, contentId,cardId, content.id, newText);
    }, 1000);
  }, [blockId, content.id, updateCardText]);

  // Set initial content only once when component mounts
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.innerHTML = content.data.text;
    }
  }, []);

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // All existing handler functions remain the same
  const handleFormat = (command) => {
    if (command === 'createLink') {
      const url = prompt('Enter URL:');
      if (url) document.execCommand(command, false, url);
    } else if (command === 'color') {
      setShowColorPicker(!showColorPicker);
    } else {
      document.execCommand(command, false, null);
    }
  };

  const handleColorSelect = (color) => {
    document.execCommand('foreColor', false, color);
    setShowColorPicker(false);
    if (editorRef.current) {
      debouncedUpdate(editorRef.current.innerHTML);
    }
  };

  const handleFontChange = (fontFamily) => {
    document.execCommand('fontName', false, fontFamily);
    if (editorRef.current) {
      debouncedUpdate(editorRef.current.innerHTML);
    }
  };


  const handleFontSizeChange = (fontSize) => {
    const size = Math.ceil(parseInt(fontSize) / 4);
    document.execCommand('fontSize', false, size);
    if (editorRef.current) {
      debouncedUpdate(editorRef.current.innerHTML);
    }
  };

  const handleTextTypeChange = (event) => {
    const format = event.target.value;
    let command = 'p';
    if (format.startsWith('Heading')) {
      const level = format.split(' ')[1];
      command = `h${level}`;
    }
    document.execCommand('formatBlock', false, `<${command}>`);
  };

  // New handlers for container interaction
  const handleClickInside = (e, containerId) => {
    e.stopPropagation();
    setFocusedContainerId(containerId);
    webBuilderDispatch({
      type: 'UPDATE_CONTENT_ID',
      payload: { contentId: containerId }
    });
  };

  const handleClickOutside = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setFocusedContainerId(null);
    }
  };

  const handleDelete = () => {

  };

  // Existing useEffect hooks
  useEffect(() => {
    setText(content.data.text);
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleSelectionChange = () => {
      const selection = window.getSelection();
      const editorContainsSelection = editorRef.current?.contains(selection.anchorNode);

      if (selection.toString().length > 0 && editorContainsSelection) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();

        const toolbarHeight = toolbarRef.current?.offsetHeight || 0;
        const toolbarWidth = toolbarRef.current?.offsetWidth || 0;

        const topPosition = rect.top - toolbarHeight - 10 > 0
          ? rect.top - toolbarHeight - 10
          : rect.bottom + 10;

        const leftPosition = Math.min(
          rect.left + rect.width / 2 - toolbarWidth / 2,
          window.innerWidth - toolbarWidth
        );

        setToolbarPosition({ top: topPosition, left: leftPosition });
        if (!showToolbar) setShowToolbar(true);
      } else if (showToolbar) {
        setShowToolbar(false);
      }
    };

    document.addEventListener('selectionchange', handleSelectionChange);
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, [showToolbar]);

  return (
    <div ref={containerRef}>

      <div
        className={`container position-relative  ${focusedContainerId === content.id ? 'focused-container' : ''
          }`}
        onMouseEnter={(e) => {
          e.currentTarget.querySelector('.container-hover-border').classList.remove('d-none');
          e.currentTarget.querySelector('.container-three-dot-menu').classList.remove('d-none');
          // e.currentTarget.querySelector('.container-six-dot-menu').classList.remove('d-none');
        }}
        onMouseLeave={(e) => {
          if (focusedContainerId !== content.id) {
            e.currentTarget.querySelector('.container-hover-border').classList.add('d-none');
            e.currentTarget.querySelector('.container-three-dot-menu').classList.add('d-none');
            // e.currentTarget.querySelector('.container-six-dot-menu').classList.add('d-none');
          }
        }}
      // onClick={(e) => handleClickInside(e, content.id)}
      >
        {/* Hover Border */}
        <div
          className="container-hover-border d-none position-absolute w-100 h-100 border border-2"
          style={{ top: 0, left: 0, pointerEvents: 'none', zIndex: 5 }}
        />

        {/* 3-Dot Menu */}
        {/* <div
          className="container-three-dot-menu d-none position-absolute p-2 bg-white shadow-sm rounded"
          style={{ zIndex: 10, top: '0px', left: '-36px' }}
        >
          <button
            className="btn btn-link text-secondary"
            onClick={(e) => {
              e.stopPropagation();
              console.log('3-dot menu clicked');
            }}
          >
            <MoreVertical size={20} />
          </button>
        </div> */}

        <div
          className="container-three-dot-menu d-none position-absolute p-2 bg-white shadow-sm rounded"
          style={{ zIndex: 10, top: '0px', left: '-36px' }}
        >
          <button
            className="btn btn-link text-secondary"
            onClick={(e) => {
              e.stopPropagation();

              // Select the text within the block
              const editor = editorRef.current;
              if (editor) {
                const range = document.createRange();
                range.selectNodeContents(editor);
                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);

                // Trigger the toolbar display logic
                const rangeBounds = range.getBoundingClientRect();
                const toolbarHeight = toolbarRef.current?.offsetHeight || 0;
                const toolbarWidth = toolbarRef.current?.offsetWidth || 0;

                const topPosition =
                  rangeBounds.top - toolbarHeight - 10 > 0
                    ? rangeBounds.top - toolbarHeight - 10
                    : rangeBounds.bottom + 10;

                const leftPosition = Math.min(
                  rangeBounds.left + rangeBounds.width / 2 - toolbarWidth / 2,
                  window.innerWidth - toolbarWidth
                );

                setToolbarPosition({ top: topPosition, left: leftPosition });
                setShowToolbar(true);
              }

              console.log('3-dot menu clicked');
            }}
          >
            <MoreVertical size={20} />
          </button>
        </div>


        {/* Drag-and-drop-btn */}
        {/* <div
          className="container-six-dot-menu d-none position-absolute p-2 bg-white shadow-sm rounded"
          style={{ zIndex: 10, top: '0px', right: '-26px' }}
        >
          <button
            className="btn btn-link text-secondary"
            onClick={(e) => {
              e.stopPropagation();
              console.log('6-dot menu clicked');
            }}
          >
            <MdOutlineDragIndicator size={20} />
          </button>
        </div> */}



        {/* Existing Toolbar */}
        {showToolbar && (
          <div
            ref={toolbarRef}
            className="position-fixed bg-white rounded shadow-sm d-flex align-items-center p-1 gap-2"
            style={{
              top: `${toolbarPosition.top}px`,
              left: `${toolbarPosition.left}px`,
              zIndex: 1050
            }}
          >
            {/* Text type dropdown */}
            <select
              onChange={handleTextTypeChange}
              className="form-select form-select-sm border-0"
              style={{ width: 'auto', minWidth: '120px' }}
            >
              {tools[0].options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            {/* Font family dropdown */}
            <select
              onChange={(e) => handleFontChange(e.target.value)}
              className="form-select form-select-sm border-0"
              style={{ width: 'auto', minWidth: '120px' }}
            >
              {fonts.map((font) => (
                <option key={font.value} value={font.value}>
                  {font.label}
                </option>
              ))}
            </select>

            {/* Font size dropdown */}
            <select
              onChange={(e) => handleFontSizeChange(e.target.value)}
              className="form-select form-select-sm border-0"
              style={{ width: 'auto', minWidth: '100px' }}
            >
              {fontSizes.map((size) => (
                <option key={size.value} value={size.value}>
                  {size.label}
                </option>
              ))}
            </select>

            <div className="vr"></div>

            <div className="btn-group btn-group-sm position-relative">
              {tools.slice(1).map((tool, index) => {
                const Icon = tool.icon;
                return (
                  <button
                    key={index}
                    onClick={() => handleFormat(tool.command)}
                    className="btn btn-light border-0"
                    title={tool.command}
                  >
                    <Icon size={16} />
                  </button>
                );
              })}

              {showColorPicker && (
                <div
                  className="position-absolute bg-white rounded shadow-sm p-2"
                  style={{
                    top: '100%',
                    left: '0',
                    width: '200px',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(10, 1fr)',
                    gap: '2px',
                    zIndex: 1060
                  }}
                >
                  {colors.map((color) => (
                    <button
                      key={color}
                      onClick={() => handleColorSelect(color)}
                      className="w-4 h-4 rounded border border-gray-200"
                      style={{ backgroundColor: color }}
                      title={color}
                    />
                  ))}
                </div>
              )}
            </div>

            <div className="vr"></div>


            <button
              className="btn btn-link text-danger"
              onClick={(e) => {
                e.stopPropagation();
                deleteColumnContent(blockId, contentId, cardId, content.id)
              }}
            >
              <Trash size={20} /> {/* Replace with an icon or text */}
            </button>
          </div>
        )}

        {/* Editor Content */}
        <div className="position-relative">
          <div
            ref={editorRef}
            contentEditable
            className="form-control border-0 shadow-none h-auto bg-transparent"
            onInput={(e) => {
              debouncedUpdate(e.currentTarget.innerHTML);
            }}
          />
        </div>
      </div>

      <style>
        {`
          .focused-container {
            outline: 1px solid #5fa1ff;
          }
          .focused-container .container-hover-border,
          .focused-container .container-three-dot-menu,
          .focused-container .card-plus-menu-icon {
            display: flex !important;
          }
            h1, h2, h3, h4, h5, h6, p {
              margin: 0;
            }
              container:not(:first-child){
                margin-top: 0;
              }
        `}
      </style>
    </div>
  );
};

export default TextEditor;